import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchHolidaysByYear } from '../../services/HolidayServices';
import { Grid, Box, Button, useTheme  } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { format, parseISO } from 'date-fns';
import logo from '../../assets/logos/phyelements_logo.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DownloadIcon from '@mui/icons-material/Download';

export default function ViewHolidays() {
    const [holidays, setHolidays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const theme = useTheme();

    useEffect(() => {
        fetchHolidays(currentYear);
    }, [currentYear]);

    const fetchHolidays = async (year) => {
        setLoading(true);
        try {
            const holidaysResponse = await fetchHolidaysByYear(year);
            const sortedHolidays = holidaysResponse.sort((a, b) => new Date(a.date) - new Date(b.date));
            setHolidays(sortedHolidays);
        } catch (error) {
            console.error('Error fetching holidays:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';
        try {
            const date = parseISO(isoDateString);
            return format(date, 'dd-MM-yyyy');
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
    
        const img = new Image();
        img.src = logo;
        
        img.onload = () => {
          doc.addImage(img, 'PNG', 13, 10);
          
          const columns = [
            { title: 'Occasion', dataKey: 'holidayName' },
            { title: 'Date', dataKey: 'date' },
            { title: 'Holiday Type', dataKey: 'holidayType' }
          ];
          const data = holidays.map(row => ({
            holidayName: row.holidayName,
            date: formatDate(row.date),
            holidayType: row.holidayType,
          }));
          doc.autoTable({
            columns,
            body: data,
            margin: { top: 46 }, 
            didDrawPage: (data) => {
              doc.text(`Annual Holidays - ${currentYear}`, data.settings.margin.left, 44);
            },
            headStyles: {
              fillColor: theme.palette.primary.main,
            }
          });
          doc.save(`Phyelements_Holidays_${currentYear}.pdf`);
        };
    };

    const columns = [
        { field: 'holidayName', headerName: <strong>Occasion</strong>, flex: 4, minWidth: 200 },
        {
            field: 'date',
            headerName: <strong>Date</strong>,
            flex: 3, minWidth: 150,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'holidayType', headerName: <strong>Holiday Type</strong>,flex: 3, minWidth: 150 },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Box sx={{ pl: 9, pr: 1, pt: 10, flex: 1, backgroundColor: theme.palette.secondary.main}}>
                <Grid container paddingLeft={1} paddingRight={1} rowSpacing={1} className='grid-container-header' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around'}} rowGap={1} marginBottom={0.5} >
                    <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontWeight: "bold" }}>Holidays for the Year {currentYear}</span>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }} sx={{justifyContent:{ md:'flex-end'}}}>
                        <Button variant="contained" color="primary" onClick={exportToPDF} startIcon={<DownloadIcon/>}
                        sx={{
                            whiteSpace: 'nowrap',
                            minWidth: { xs: '110px', sm: 'auto' },
                            padding: { xs: '6px 12px', sm: '8px 16px' }
                          }}>
                            Download as PDF
                        </Button>
                    </Grid>
                </Grid>
                <Grid container padding={2} style={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} >
                        <DataGrid
                            localeText={{ noRowsLabel: "No Holidays To Show" }}
                            rows={holidays}
                            columns={columns}
                            getRowId={(row) => row._id}
                            autoHeight
                            hideFooterPagination
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loading}
                            sx={{
                                '& .MuiDataGrid-selectedRowCount': {
                                  display: 'none',
                                },
                              }}
                        />
                    </Grid>                   
                </Grid>
               
            </Box>
        </Box>
    );
}
