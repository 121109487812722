import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, TextField, FormControl, MenuItem, Select, Button ,Paper, Dialog, DialogTitle, useTheme, DialogContent, Alert, DialogActions, List, ListItem, ListItemIcon , IconButton } from '@mui/material';
import { getEmployeeById } from '../../services/employeeService';
import { getTimeTrackerData, postTimeTrackerData, fetchHolidaysByYear, putTimeTrackerData} from '../../services/TimeTrackerServices';
import { NotificationManager } from 'react-notifications';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, startOfWeek, isSameMonth, isWeekend } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CancelIcon from '@mui/icons-material/Cancel';

function TimeTracker() {
  const id =  localStorage.getItem("employeeId");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  
  const [employeeName, setEmployeeName] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [month, setMonth] = useState(currentMonth);
  const [weekFilter, setWeekFilter] = useState('All');
  const [weeks, setWeeks] = useState([]);
  const [loggedHours, setLoggedHours] = useState({});
  const [holidays, setHolidays] = useState([]);
  const [weeklyTotals, setWeeklyTotals] = useState([]);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [existingDates, setExistingDates] = useState(new Set());
  const [isModified, setIsModified] = useState(false);
  const [initialLoggedHours, setInitialLoggedHours] = useState({});
  const [selectedFields, setSelectedFields] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery('(max-width:800px)'); 
  const theme = useTheme();

  useEffect(() => {
    fetchEmployee();
    fetchTimeTrackerData();
    if (month !== null) {
      generateWeeks(currentYear, month);
      fetchHolidaysByMonth(currentYear, month);
    }
  }, [month]);  

  useEffect(() => {
    calculateTotals();
    setLoading();
    if (JSON.stringify(loggedHours) !== JSON.stringify(initialLoggedHours)) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [loggedHours,initialLoggedHours]);

  const fetchEmployee = async () => {
    try {
      const response = await getEmployeeById(id);
      if (response) {
        setEmployeeName(response.employeeName || '');
        setEmployeeNumber(response.employeeNumber || '');
      }
    } catch (error) {
      NotificationManager.error('Failed to fetch employee data.');
    }
  };

  const fetchTimeTrackerData = async () => {
    try {
      const data = await getTimeTrackerData(id, currentYear, monthNames[month]);
  
      if (data && data.length > 0 && data[0]?.timesheets?.length > 0) {
        const timeSheet = data[0].timesheets;
        const allLoggedHours = {};
        const dates = new Set();
  
        timeSheet.forEach(sheet => {
          sheet.months.forEach(monthData => {
            monthData.days.forEach(day => {
              allLoggedHours[day.date] = day.hoursLogged === 0 ? '0' : day.hoursLogged;
              dates.add(day.date);
            });
          });
        });
  
        setLoggedHours(allLoggedHours);
        setExistingDates(dates);
        setInitialLoggedHours(allLoggedHours);
      } else {
        console.error("No time tracker data available.");
      }
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        NotificationManager.error('Failed to fetch time tracker data.');
      } else {
        console.error("No data found, or the server returned a 404 response.");
      }
    }
  };  
  
  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleWeekFilterChange = (event) => {
    setWeekFilter(event.target.value);
  };

  const generateWeeks = (year, monthIndex) => {
    const start = startOfMonth(new Date(year, monthIndex));
    const end = endOfMonth(new Date(year, monthIndex));
    const startDate = startOfWeek(start, { weekStartsOn: 0 });
    
    const days = eachDayOfInterval({ start: startDate, end: end });
    const weeks = [];
    let week = [];
    let weekHasWorkDays = false;
    let currentWeekIndex = 0;
  
    days.forEach((date, index) => {
      if (week.length < 7) {
        week.push(date);
        if (!isWeekend(date) && isSameMonth(date, new Date(year, monthIndex))) {
          weekHasWorkDays = true;
        }
        if (format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
          currentWeekIndex = weeks.length;
        }
      } else {
        weeks.push({ days: week, hasWorkDays: weekHasWorkDays });
        week = [date];
        weekHasWorkDays = !isWeekend(date) && isSameMonth(date, new Date(year, monthIndex));
      }
    });
  
    if (week.length > 0) {
      weeks.push({ days: week, hasWorkDays: weekHasWorkDays });
    }
  
    setWeeks(weeks);
    setWeekFilter('All');
  };

    const handleHoursChange = (date, hours) => {
      if (hours === '') {
        setLoggedHours(prev => {
          const updatedHours = { ...prev };
          delete updatedHours[date];
          return updatedHours;
        });
      } else {
        const numHours = Number(hours);
        if (numHours >= 0 && numHours <= 10) {
          setLoggedHours(prev => ({
            ...prev,
            [date]: hours
         }));
        } else {
          console.error("Invalid input: Hours should be a whole number between 0 and 10.");
      }
    }
  };  

  const handleSaveAndUpdate = async () => {
    setLoading(true);
    try {
      const hoursExceedingLimit = Object.values(loggedHours).some(hours => hours > 10);

      if (hoursExceedingLimit) {
          NotificationManager.warning('Logged hours cannot be more than 10 hours per day.');
          return;
      }
      const newDays = Object.keys(loggedHours)
        .filter(date => !existingDates.has(date))
        .map(date => ({
          date,
          hoursLogged: loggedHours[date]
        }));
  
      const updatedDays = Object.keys(loggedHours)
        .filter(date => existingDates.has(date))
        .map(date => ({
          date,
          hoursLogged: loggedHours[date]
        }));
  
      const monthName = monthNames[month];
      
      const dataToSaveAndUpdate = {
        year: currentYear,
        month: monthName,
        days: [...newDays, ...updatedDays]
      };
  
      if (newDays.length > 0 || updatedDays.length > 0) {
        const response = await (newDays.length > 0
          ? postTimeTrackerData(id, dataToSaveAndUpdate)
          : putTimeTrackerData(id, dataToSaveAndUpdate));

        NotificationManager.success('Time tracker data updated successfully.');
      } else {
        NotificationManager.info('No data to update.');
      }
    } catch (error) {
      NotificationManager.error('Failed to update time tracker data.');
      setLoading(false);
    }
  };
  
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const fetchHolidaysByMonth = async (year, monthIndex) => {
    try {
      const response = await fetchHolidaysByYear(year);
      const holidays = response.filter(holiday => {
        const holidayDate = new Date(holiday.date);
        return holidayDate.getFullYear() === year && holidayDate.getMonth() === monthIndex;
      });
      setHolidays(holidays);
    } catch (error) {
      NotificationManager.error('Failed to fetch holidays.');
    }
  };

  const calculateTotals = () => {
    const newWeeklyTotals = weeks.map(weekInfo => {
      return weekInfo.days.reduce((total, date) => {
        if (isSameMonth(date, new Date(currentYear, month))) {
          const dateString = format(date, 'yyyy-MM-dd');
          const hours = parseFloat(loggedHours[dateString]) || 0;
          return total + hours;
        }
        return total;
      }, 0);
    });
  
    setWeeklyTotals(newWeeklyTotals);
  
    const newMonthlyTotal = newWeeklyTotals.reduce((sum, weekTotal) => sum + weekTotal, 0);
    setMonthlyTotal(newMonthlyTotal);
  };

  const handleCancel = () => {
    setLoggedHours(prev => {
      const newLoggedHours = { ...prev };
      
      selectedFields.forEach(date => {
        newLoggedHours[date] = '';
      });
      return newLoggedHours;
    });
    setSelectedFields(new Set());
    fetchTimeTrackerData();
  };  
  
  const handleFieldFocus = (date) => {
    setSelectedFields(prev => new Set(prev.add(date)));
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
  }; 
  
  const weekDaysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekDaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const renderWeeks = () => {
    return (
      <>
        <Grid style={{backgroundColor:'#fff'}} paddingLeft={0.5} paddingRight={0.25} borderRadius={2} >  
        <Grid container sx={{ paddingLeft: { xs: 0, sm: 3.5 }}}>
          {(isMobile ? weekDaysShort : weekDaysFull).map((day, index) => (
            <Grid
              item
              xs={1.7}
              md={1.65}
              key={index}
              sx={{
                border: '1px solid #ddd',
                padding: 1,
                backgroundColor: '#f5f5f5',
                marginTop: '10px',
              }}
            >
              <Typography
                variant="h6"
                align="center"
                color={ theme.palette.primary.main}
                style={{ fontWeight: 'bold' }}
              >
                {day}
              </Typography>
            </Grid>
          ))}
        </Grid>
  
        {weeks.map((weekInfo, weekIndex) => {
          if (weekFilter !== 'All' && weekFilter !== `Week ${weekIndex + 1}`) {
            return null;
          }
  
          return (
            <Grid container key={weekIndex} sx={{ paddingLeft: { xs: 0, sm: 3.5 }}}>
              {weekInfo.days.map((date, index) => (
                <Grid
                  item
                  xs={1.7}
                  md={1.65}
                  key={index}
                >
                  {isSameMonth(date, new Date(currentYear, month)) && (
                    <>
                      <Paper
                        elevation={2}
                        sx={{
                          position: "relative",
                          padding: 1,
                          textAlign: "center",
                          height: {md:"70px", sm:"50px", xs:"35px"},
                          border: "1px solid #A0A0A0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: isWeekend(date)
                            ? "#f7f7d0"
                            : loggedHours[format(date, "yyyy-MM-dd")]
                            ? "#d3ffd3"
                            : "#ffff",
                        }}
                        onClick={() => {
                          if (!isWeekend(date)) {
                            document.getElementById(`textField-${format(date, "yyyy-MM-dd")}`).focus();
                          }
                        }}
                      >
                        <Typography
                          fontWeight="bold"
                          sx={{
                            color: theme.palette.primary.main,
                            position: "absolute",
                            // top: 0,
                            bottom:0,
                            textAlign: 'center',
                            margin: 0,
                            // right: 0,
                            padding: { md: "0px", xs: "2px" },
                            // backgroundColor: "#f5f5f5",
                            fontSize: { xs: "9px", sm: "10px", md: "14px" },
                            borderRadius: '50px'
                          }}
                        >
                          {format(date, "dd")}
                        </Typography>

                        {!isWeekend(date) && (
                        <TextField
                          id={`textField-${format(date, "yyyy-MM-dd")}`}
                          variant="outlined"
                          size="small"
                          value={loggedHours[format(date, "yyyy-MM-dd")] || ""}
                          onChange={(e) =>
                            handleHoursChange(format(date, "yyyy-MM-dd"), e.target.value)
                          }
                          onFocus={() => handleFieldFocus(format(date, 'yyyy-MM-dd'))}
                          // InputProps={{
                          //   inputProps: {
                          //     style: {
                          //       textAlign: "center",
                          //       fontWeight: "bold",
                          //       fontSize: "13px",
                          //       "@media (min-width:600px)": {
                          //         fontSize: "25px", 
                          //       },
                          //       "@media (min-width:960px)": {
                          //         fontSize: "35px", 
                          //       },
                          //     },
                          //   },
                          // }}
                          onKeyDown={(e) => {
                            if (e.key === '.') {
                              e.preventDefault();
                            }
                            const currentValue = e.target.value;
                            // Prevent leading zeros if the input is not exactly '0'
                            if (currentValue === '0' && e.key === '0') {
                              e.preventDefault();
                            }
                          }}
                          sx={{
                            border:"1px solid #cccccc",
                            borderRadius: '15px',
                            width: "100%",
                            maxWidth: { xs: "40px", sm: "40px" },
                            marginBottom: '5px',
                            textAlign: "center",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                            },
                            "& .MuiInputBase-input": {
                              padding: { xs: "0px", sm: "0px" },
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: { xs: '0.75rem', sm: '1rem' },
                            },
                          }}
                        />
                      )}
                      </Paper>
                    </>
                  )}
                </Grid>
              ))}

              {weekInfo.days.length < 7 &&
                Array.from({ length: 7 - weekInfo.days.length }).map((_, i) => (
                  <Grid
                    item
                    xs={2}
                    sm={6}
                    md={1}
                    key={i}
                  />
                ))}

            </Grid>
          );
        })}

          <Typography variant="h6" style={{fontWeight:'bold'}} marginTop={2} sx={{ paddingLeft: { xs: 0, sm: 3.5 }}}>
          Monthly Total: {monthlyTotal} hours
        </Typography>
  
        <Grid container sx={{ justifyContent: 'center', padding: 1, gap: 2, marginBottom: { xs: 0, sm: 0, md:5 } }}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} disabled={!isModified || loading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveAndUpdate} disabled={!isModified || loading}>
            Save
          </Button>
        </Grid>
        <Dialog open={openDialog} onClose={handleCancelDialog}>
        <DialogTitle className="confirmDialogTitle">Unsaved Changes Confirmation <IconButton aria-label="close" onClick={handleCancelDialog}><CancelIcon /></IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity='warning' className='confirmDialogMsg'>
            There are unsaved changes that will be lost. Do you wish to continue?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCancelDialog} color="primary"> No </Button>
          <Button variant='contained'  onClick={() => {handleCancel(); handleCancelDialog();}} color="primary"> Continue </Button>
        </DialogActions>
      </Dialog>
       </Grid>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", marginTop:0.5}}>
      <Box sx={{ pl: 9, pr: 1, pt: 10, flex: 1, backgroundColor: "#FAEAEA" }}>
        
        <Grid
          container
          paddingLeft={1} paddingRight={1} rowGap={1}
          marginBottom={0.5}
          sx={{
            paddingTop: {xs: 0, sm: 1, md: 0},
            paddingBottom: {xs: 1, sm: 1, md: 0},
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            backgroundColor: "#fff",
            alignItems: "center", 
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={4} md={4} style={{ display: 'flex', alignItems: 'center' }} className='grid-container-header'>
              <span style={{fontWeight:"bold"}}>My Timesheet Tracker</span>
          </Grid>
          <Grid item xs={6} sm={4} md={6.15} textAlign='right'>
            <FormControl sx={{ marginRight: 2 ,width:{md:'30%',xs:'90%'}}}>
              <Select value={month} onChange={handleMonthChange} displayEmpty>
                {Array.from({ length: 3 }, (_, i) => {
                  const monthIndex = currentMonth - 1 + i; 
                  const monthName = format(new Date(currentYear, monthIndex), 'MMMM');
                  return (
                    <MenuItem
                      key={i}
                      value={monthIndex}
                      sx={{
                        fontWeight: currentMonth === monthIndex ? 'bold' : 'normal',
                      }}
                    >
                      {monthName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid> 
  
          <Grid item xs={6} sm={4} md={1.85}>
            <FormControl fullWidth>
              <Select value={weekFilter} onChange={handleWeekFilterChange} displayEmpty>
                <MenuItem value="All">All Weeks</MenuItem>
                {weeks.map((weekInfo, weekIndex) => {

                  const monday = weekInfo.days.find((day) => new Date(day).getDay() === 1);
                  
                  const friday = weekInfo.days.find((day) => new Date(day).getDay() === 5);
                  
                  const firstDay = new Date(weekInfo.days[0]);

                  const lastDay = new Date(weekInfo.days[weekInfo.days.length - 1]);

                  const startOfMonth = new Date(currentYear, month, 1);

                  const endOfMonth = new Date(currentYear, month + 1, 0);

                  const adjustedStart = monday ? (monday < startOfMonth ? startOfMonth : monday) : startOfMonth;

                  const adjustedEnd = friday ? (friday > endOfMonth ? endOfMonth : friday) : endOfMonth;

                  const formatDate = (date) => {
                    return date ? `${String(date.getDate()).padStart(2, '0')}/${date.toLocaleString('en-GB', { month: 'short' })}/${date.getFullYear()}` : '';
                  };

                  return (
                    <MenuItem
                      key={weekIndex}
                      value={`Week ${weekIndex + 1}`}
                      sx={{
                        fontWeight: weekFilter === `Week ${weekIndex + 1}` ? 'bold' : 'normal',
                      }}
                    >
                      {`Week ${weekIndex + 1} (${formatDate(adjustedStart)} to ${formatDate(adjustedEnd)})`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
  
        <Grid container rowGap={1} columnSpacing={1}>
          <Grid item md={10} sm ={12} xs={12}>
            {renderWeeks()}
          </Grid>

          <Grid item md={2} xs={12} sx={{ minWidth: '120px' }} >
            <Paper elevation={2} sx={{ padding: 1.75 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>Legend</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Box sx={{ width: 16, height: 16, backgroundColor: '#d3ffd3', marginRight: 1 }} />
                <Typography variant="body2">Filled</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Box sx={{ width: 16, height: 16, backgroundColor: '#f7f7d0', marginRight: 1 }} />
                <Typography variant="body2">Weekend</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 16, height: 16, backgroundColor: '#ffffff', marginRight: 1, border: '1px solid #A0A0A0' }} />
                <Typography variant="body2">Working Day</Typography>
              </Box>
            </Paper>
            <Paper elevation={2} sx={{ padding: 1.75, marginTop: 1, marginBottom: 6 }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>Instructions</Typography>
              <List>
                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Fill the timesheet before Friday of every week.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Fill the number of hours worked in the office correctly.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1 }}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">A minimum of 8 hours of effort is a must to fill the timesheet.</Typography>
                </ListItem>

                <ListItem sx={{ alignItems: 'flex-start' }} disableGutters>
                  <ListItemIcon sx={{ minWidth: 'auto', marginTop: '4px', marginRight: 1}}>
                    <FiberManualRecordIcon sx={{ fontSize: '8px' }} />
                  </ListItemIcon>
                  <Typography variant="body2">Leaves and holidays should be mentioned as zero hours.</Typography>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ); 
}

export default TimeTracker;
