import React, { useState, useEffect } from 'react';
import { Dialog ,DialogTitle, DialogContent,DialogContentText, DialogActions, IconButton, TextField, Button, Select, MenuItem, FormControl, InputLabel ,Switch, FormControlLabel, Typography, useTheme ,Alert} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createEmployee , updateEmployee, getEmployeesByRole} from '../../services/employeeService';
import dayjs from 'dayjs';
import { NotificationManager } from "react-notifications";
import { fetchUserRoles } from '../../services/UserRolesServices';

function AddEmployee({ isAddClicked, handleCloseDialog ,employeeData}) {
  const theme = useTheme();
  const [employeeId, setEmployeeId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [employeeEmailId, setEmployeeEmailId] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [designation, setDesignation] = useState('');
  const [uan, setUan] = useState('');
  const [selectedRole, setSelectedRole] = React.useState('');
  const [activeStatus, setActiveStatus] = useState(true);
  const [reportingTo, setReportingTo] = useState('');
  const [reportingRoles, setReportingRoles] = useState([]);
  const [roleConfirmPopup, setRoleConfirmPopup] = useState(false);
  const [userRoles, setUserRoles] = React.useState([]);
  const [gender, setGender] = React.useState('');
  
  const [employeeIdErrorMessage, setEmployeeIdErrorMessage] = useState('');
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [emailError, setEmailError] = React.useState(null);
  const [designationErrMsg, setDesignationErrMsg] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  
  const getUserroles = async () =>{
    const data = await fetchUserRoles();
    const employeeRoleId = data.find(data => data.userRole == "Employee")?._id;
    setSelectedRole(employeeRoleId);
    setUserRoles(data);
}

const getReportingRoles = async () =>{
  const data = await getEmployeesByRole();
  setReportingRoles(data);
  setReportingTo('');
}

  useEffect(() => {
    if (employeeData) {
      setEmployeeId(employeeData.employeeNumber);
      setFirstName(employeeData.firstName);
      setLastName(employeeData.lastName);
      setEmployeeEmailId(employeeData.emailId);
      setSelectedDate(employeeData.dateOfJoining ? dayjs(employeeData.dateOfJoining) : null);
      setGender(employeeData.gender);
      setDesignation(employeeData.designation);
      setUan(employeeData.uan);
      setActiveStatus(employeeData.activeStatus ? true : false);
      setSelectedRole(employeeData.assignedRoleId);
      setReportingTo(employeeData.reportingTo);
    } else {
      getUserroles();
      getReportingRoles();
    }
  }, [employeeData]);

  const handleEmployeeId = (e) => {
    const id = e.target.value;
    setEmployeeId(id);
    if (!id) {
      setEmployeeIdErrorMessage("required");
    } else {
      if (/^[a-zA-Z0-9]+$/.test(id)) {
        setEmployeeId(id);
        setEmployeeIdErrorMessage("");
      } else {
        setEmployeeIdErrorMessage("invalid id!!");
      }
    }
  };

  const handleFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
    if (!firstName) {
      setFirstNameErrorMessage("required");
    } else {
      if (/^[a-zA-Z\s'-]+$/.test(firstName)) {
        setFirstName(firstName);
        setFirstNameErrorMessage("");
      } else {
        setFirstNameErrorMessage("invalid first name!!");
      }
    }
  };

  const handleLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
    if (!lastName) {
      setLastNameErrorMessage("required");
    } else {
      if (/^[a-zA-Z\s'-]+$/.test(lastName)) {
        setLastName(lastName);
        setLastNameErrorMessage("");
      } else {
        setLastNameErrorMessage("invalid last name!!");
      }
    }
  };


  const handleEmailChange = (event) => {
    setEmployeeEmailId(event.target.value);
    setEmailError(null);
  };

  const handleEmailBlur = () => {
    if (employeeEmailId.trim() === '') {
      setEmailError('Email is required');
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(emailRegex.test(employeeEmailId) ? '' : 'Invalid email format');
    }
  };  

  const handleJoiningDate = (selectedDate) => {
    setSelectedDate(selectedDate);
  }

  const handleDesignation = (e) => {
    const designation = e.target.value;
    setDesignation(designation);
    if (!designation) {
      setDesignationErrMsg("");
    } else {
      if (/^[a-zA-Z\s'-]+$/.test(designation)) {
        setDesignation(designation);
        setDesignationErrMsg("");
      } else {
        setDesignationErrMsg("invalid, only alphabets are allowed!");
      }
    }
  };

  const handleUan = (e) => {
    const uan = e.target.value;
    setUan(uan);
  };

  const handleChange = (event) => {
    const selectedRole = event.target.value;
    const selectedRoleName = userRoles.find(role => role._id === selectedRole)?.userRole;
    if (selectedRoleName !== "Employee") {
      setRoleConfirmPopup(true);
      setSelectedRole(selectedRole);
    } else {
      setSelectedRole(selectedRole);
    }
  };

  const handleConfirmPopupContinue = () => {
    setSelectedRole(selectedRole);
    setRoleConfirmPopup(false);
  };

  const handleConfirmPopupClose = () => {
    setSelectedRole(userRoles.find(data => data.userRole == "Employee")?._id);
    setRoleConfirmPopup(false);
  };

  const handleReportingChange = (event) => {
    setReportingTo(event.target.value);
  };

  const handleActiveStatusChange = (e) => {
    setActiveStatus(e.target.checked);
  };

  const handleSave = async () => {
    const EmployeeId = employeeId.trim().toLowerCase();
    const EmailId = employeeEmailId.trim().toLowerCase();
    const newEmployee = {
      employeeNumber: EmployeeId,
      firstName:firstName.trim(),
      lastName:lastName.trim(),
      emailId: EmailId,
      dateOfJoining: selectedDate.toISOString(),
      gender: gender,
      activeStatus: activeStatus,
      designation: designation,
      uan: uan,
      assignedRoleId: selectedRole,
      reportingTo: reportingTo,
    }
    try{
      if (employeeData) {        
        await updateEmployee(employeeData._id, newEmployee);
        NotificationManager.success("Employee Updated Succesfully");
      } else {
        const response = await createEmployee(newEmployee);
        NotificationManager.success(response.message);
      }
      handleClose();
    } catch (error) {
      console.error("error",error);
      NotificationManager.error(error.response.data.message);
    }
  };

  const handleClose = () => {
    setEmployeeIdErrorMessage('');
    setFirstNameErrorMessage('');
    setLastNameErrorMessage('');
    setEmployeeId('');
    setFirstName('');
    setLastName('');
    setEmployeeEmailId('');
    setEmailError('');
    setSelectedDate(null);
    setGender('');
    setDesignation('');
    setUan('');
    setDesignationErrMsg('');
    setActiveStatus(true);
    setSelectedRole(userRoles.find(data => data.userRole == "Employee")?._id);
    setReportingTo('');
    handleCloseDialog(false);
  };

  const selectedRoleName = userRoles.find((role) => role._id === selectedRole)?.userRole || '';

  return (
    <Dialog
      open={isAddClicked}
      onClose={handleClose}
      PaperProps={{
        sx: { width: '400px' }, className: 'dialog'
      }}
    >
      <DialogTitle className= 'dialogTitle'>
        <strong>{employeeData ? 'Edit Employee' : 'Add Employee'}</strong>
        <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            required
            fullWidth
            id="employeeId"
            label="Id"
            margin="normal"
            error={employeeIdErrorMessage}
            name="employeeId"
            onChange={(e) => handleEmployeeId(e)}
            onBlur={handleEmployeeId}
            value= {employeeId}
            helperText = {employeeIdErrorMessage}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            required
            fullWidth
            id="firstName"
            label="First Name"
            margin="normal"
            error={firstNameErrorMessage}
            name="firstName"
            value= {firstName}
            onChange={(e) => handleFirstName(e)}
            onBlur={handleFirstName}
            helperText = {firstNameErrorMessage}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            margin="normal"
            error={lastNameErrorMessage}
            name="lastName"
            value= {lastName}
            onChange={(e) => handleLastName(e)}
            onBlur={handleLastName}
            helperText = {lastNameErrorMessage}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            margin="normal"
            id="email"
            label="Email Id"
            type="email"
            fullWidth
            value={employeeEmailId}
            onChange={(e) => handleEmailChange(e)}
            onBlur={handleEmailBlur}
            error={emailError}
            helperText={emailError}
            required
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '100%', mt: 2 }}
            label="Joining Date *"
            value={selectedDate}
            onChange={(e) => handleJoiningDate(e)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
        <FormControl sx={{ width:'100%'}}>
          <InputLabel id="simple-gender-label">Gender</InputLabel>
          <Select
            labelId="simple-gender-label"
            id="gender-select"
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
        </FormControl>
        </div>
        <TextField
            fullWidth
            id="designation"
            label="Designation"
            margin="normal"
            error={designationErrMsg}
            value= {designation}
            onChange={(e) => handleDesignation(e)}
            onBlur={handleDesignation}
            helperText={designationErrMsg}
          />
        <TextField
          fullWidth
          id="uan"
          label="UAN"
          margin="normal"
          value= {uan}
          onChange={(e) => handleUan(e)}
        />
        <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
        <FormControl sx={{ width:'100%'}}>
          <InputLabel id="demo-simple-select-label">Assign Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRole}
            label="AssignRole"
            onChange={handleChange}
          >
            {userRoles.map((row) => (
              <MenuItem value={row._id}>{row.userRole}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
        <FormControl sx={{ width:'100%'}}>
          <InputLabel id="reporting-select-label">Reporting To *</InputLabel>
          <Select
            labelId="reporting-select-label"
            id="reporting-select"
            value={reportingTo}
            label="Reporting To * "
            onChange={handleReportingChange}
          >
            {reportingRoles.map((role) => (
              <MenuItem value={role._id}>{role.firstName} {role.lastName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
        <FormControlLabel
              control={
                <Switch
                  checked={activeStatus}
                  onChange={handleActiveStatusChange}
                  color="primary"
                />
              }
              label={<Typography>{activeStatus ? 'Active' : 'Inactive'}</Typography>}
            />
        </div>
      </DialogContent>
      <DialogActions className='dialogActions'>
        <Button onClick={handleClose} variant="outlined" >Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary" 
          disabled={!employeeId || !firstName || !lastName || !employeeEmailId || !selectedDate || !reportingTo || !!employeeIdErrorMessage || !!firstNameErrorMessage || !!lastNameErrorMessage || !!emailError || !!designationErrMsg }>
          {employeeData ? 'Update' : 'Save'}</Button>
      </DialogActions>

      <Dialog
        open={roleConfirmPopup}
        onClose={handleConfirmPopupClose}
        PaperProps={{ sx: { width: '350px' } }}
      >
        <DialogTitle className="confirmDialogTitle">
          Role Change Confirmation <IconButton aria-label="close" onClick={handleConfirmPopupClose}><CancelIcon /> </IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity="warning" className='confirmDialogMsg'>You selected <strong>{selectedRoleName}</strong> role for <strong>{firstName} {lastName}</strong>, Do you wish to continue?</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmPopupClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmPopupContinue} variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>

    </Dialog>
  );
};

export default AddEmployee;
