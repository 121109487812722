import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Grid, Box, Button,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useTheme } from '@mui/material';
import AddAsset from './AddAsset';
import { getAssetsList } from '../../services/assetsService';
import { format, parseISO } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import AssignAsset from './AssignAsset';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';


export default function AssetManagement() {
    const theme = useTheme();

    const [popupOpen, setPopupOpen] = useState(false);
    const [assignPopupOpen, setAssignPopupOpen] = useState(false);
    const [assetsList, setAssetsList] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleAssignClick = () => {
        setAssignPopupOpen(true);
    };
    const handleCloseAssignPopup = () => {
        setAssignPopupOpen(false);
    };
    const handleAddClick = () => {
        setSelectedAsset(null);
        setPopupOpen(true);
    };

    const handleEditClick = (asset) => {
        setSelectedAsset(asset);
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';
        const date = parseISO(isoDateString);
        return format(date, 'dd-MM-yyyy');
      };
    
    const columns = [
        { field: 'assetId', headerName: <strong>Asset ID</strong>, width: 100 },
        { field: 'type', headerName: <strong>Type</strong>, width: 150,
            valueGetter: (params) => params.type
         },
        { field: 'model', headerName: <strong>Model</strong>, width: 150,
            valueGetter: (params) => params.model
         },
        { field: 'dateOfPurchase', headerName: <strong>Purchased Date</strong>, width: 200,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'description', headerName: <strong>Description</strong>, width: 250 },
        { field: 'assignedEmployee', headerName: <strong>Assigned Employee</strong>, width: 150,
            valueGetter: (params) => {
                return params ? `${params.firstName || ''} ${params.lastName || ''}`  : '-----';
              }
         },
        {
            field: 'actions',
            headerName: <strong>Actions</strong>,
            width: 250,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <BorderColorIcon onClick={() => handleEditClick(params.row)}/>
            )
        }
    ];

    const fetchAssetData = async () => {
        try {
          const response = await getAssetsList();
          setAssetsList(response);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching Asset List:', error);
          setLoading(false);
        }
      }

    useEffect(() => {
        fetchAssetData();
      }, [popupOpen,selectedAsset]);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }} >
            <Box sx={{ pl: 9, pr: 1, pt: 10, flex: 1, backgroundColor: theme.palette.secondary.main, }}>
                <Grid container paddingLeft={1} paddingRight={1} rowGap={1} className='grid-container-header' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around' }} marginBottom={0.5} >
                    <Grid item xs={12} sm={8} md={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontWeight: "bold" }}>Asset Management</span>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }}>
                        <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} onClick={handleAssignClick} startIcon={<PlaylistAddCheckIcon />}>
                            Manage Assets
                        </Button>
                        <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} onClick={handleAddClick} startIcon={<AddIcon />}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid container padding={2} style={{ backgroundColor: '#fff' }}>
                <DataGrid
                        localeText={{ noRowsLabel: "No Assets available. Please add a new Asset." }}
                        rows={assetsList}
                        getRowId={(row) => row._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 8,
                              },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        pagination
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                        sx={{
                            '& .MuiDataGrid-selectedRowCount': {
                              display: 'none',
                            },
                          }}   
                    />
                </Grid>
                <AddAsset open={popupOpen} handleCloseDialog={handleClosePopup} initialData={selectedAsset} />
                <AssignAsset open={assignPopupOpen} handleCloseDialog={handleCloseAssignPopup} />
            </Box>
        </Box>
    );
}
