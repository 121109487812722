import DashboardComponent from "../Dashboard/Dashboard";
import UserRoles from "../components/UserRole/UserRoles";
import ManageEmployee from "../components/Employee/ManageEmployee";
import Modules from "../components/Modules/Modules";
import UserRolePermissions from "../components/UserPermissions/UserPermissions";
import UpdateProfile from "../components/UpdateProfile/UpdateProfile";
import ManageHolidays from '../components/Holidays/ManageHolidays.js';
import ViewHolidays from '../components/Holidays/ViewHolidays.js';
import TimeTracker from "../components/Time Tracker/TimeTracker.js";
import ApplyLeave from "../components/Leaves/ApplyLeave.js";
import LeaveApproval from "../components/Leaves/LeaveApprovals.js";
import TimeTrackerView from "../components/Time Tracker/TimeTrackerView.js";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BentoIcon from '@mui/icons-material/Bento';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DevicesIcon from '@mui/icons-material/Devices';
import ManageAsset from "../components/Assets/ManageAsset.js";
import MyWeeklyReport from "../components/WeeklyReport/MyWeeklyReport.js";
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewWeeklyReports from "../components/WeeklyReport/ViewWeeklyReports.js";
import AssessmentIcon from '@mui/icons-material/Assessment';

const AllRoutes = [
  // {
  //   path: "/dashboard",
  //   title: "Dashboard",
  //   element: <DashboardComponent />,
  //   icon: DashboardIcon,
  //   key: 'dashboard'
  // },
  {
    path: "/updateprofile/:id",
    title: "My Profile",
    element: <UpdateProfile />,
    icon: RecentActorsIcon,
    key: 'myprofile'
  },
  {
    path: "/myWeeklyReport",
    title: "My Weekly Report",
    element: <MyWeeklyReport />,
    icon: SummarizeIcon,
    key: 'myweeklyreport'
  },
  {
    path: "/viewWeeklyReports",
    title: "View Weekly Reports",
    element: <ViewWeeklyReports />,
    icon: AssessmentIcon,
    key: 'viewweeklyreports'
  },
  {
    path: "/userroles",
    title: "Roles",
    element: <UserRoles />,
    icon: AdminPanelSettingsIcon,
    key: "roles",
  },
  {
    path: "/employees",
    title: "Employees",
    element: <ManageEmployee />,
    icon: PeopleAltIcon,
    key: "employees",

  },
  {
    path: "/modules",
    title: "Modules",
    element: <Modules />,
    icon: BentoIcon,
    key: "modules",
  },
  {
    path: "/permissions",
    title: "Permissions",
    element: <UserRolePermissions />,
    icon: VerifiedUserIcon,
    key: 'permissions'
  },
  {
    path: "/managetimesheets",
    title: "Manage Timesheets",
    element: <TimeTrackerView />,
    icon: ViewTimelineIcon,
    key: 'managetimesheet'
  },
  {
    path: "/mytimesheet",
    title: "My Timesheet Tracker",
    element: <TimeTracker />,
    icon: ViewTimelineIcon,
    key: "mytimesheettracker",
  },
  {
    path: "/applyleave",
    title: "Apply Leave",
    element: <ApplyLeave />,
    icon: AssignmentIcon,
    key:'applyleave'
  },
  {
    path: "/leaveapprovals",
    title: "Leave Approvals",
    element: <LeaveApproval />,
    icon: AssignmentTurnedInIcon,
    key: 'leaveapprovals'
  },
  // {
  //   path: "/payslips",
  //   title: "Payslips",
  //   element: <DashboardComponent />,
  //   icon: ReceiptIcon,
  //   key: 'payslips'
  // },
  {
    path: "/manageHolidays",
    title: "Manage Holidays",
    element: <ManageHolidays />,
    icon: CalendarMonthIcon,
    key: "manageholidays",
  },
  {
    path: "/viewHolidays",
    title: "View Holidays",
    element: <ViewHolidays />,
    icon: CalendarMonthIcon,
    key: 'viewholidays'
  },
  {
    path: "/assetManagement",
    title: "Asset Management ",
    element: <ManageAsset />,
    icon: DevicesIcon,
    key: 'assetmanagement'
  },

]

export default AllRoutes;