import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';

export const fetchUserRoles = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/userRoles`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user roles:', error);
      throw error;
    }
};

export const createUserRoles = async (formData) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/userRoles`, formData);
        return response.data;
    } catch (error) {
        console.error('Error creating user roles:', error);
        throw error;
    }
};

export const updateUserRole = async (id, formData) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/userRoles/${id}`, formData);
        return response.data;
    } catch (error) {
        console.error('Error updating user role:', error);
        throw error;
    }
};

export const getRoleIdByName = async (roleName) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/userRoles/getRoleId`, { "roleName":roleName});
        return response.data.roleId;
    } catch (error) {
        console.error('Error creating user roles:', error);
        throw error;
    }
};

export const getRoleNameById = async (roleId) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/userRoles/getRoleName/${roleId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching role name:', error);
        throw error;
    }
};