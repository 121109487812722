import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import { addHoliday, updateHoliday, deleteHoliday, fetchHolidaysByYear } from '../../services/HolidayServices';
import { parseISO } from 'date-fns';
import { Alert, FormControl, Select, MenuItem, InputLabel, Typography, useTheme, IconButton } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/logos/phyelements_logo.png';
import HolidayPopup from './HolidayPopup';
import { fetchDistinctYears } from '../../services/HolidayServices';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GetAppIcon from '@mui/icons-material/GetApp';
import { format } from 'date-fns';

const randomId = () => Math.random().toString(36).substring(2, 15);

function EditToolbar({ setRows, setRowModesModel, rows, selectedMonth, handleMonthChange, handleImportClick, currentYear, handleYearChange, nextFiveYears, fetchHolidays,filteredHolidays }) {
 const theme = useTheme(); 

  const handleClick = () => {
    const id = randomId();
    const newHoliday = { 
      _id: id, 
      holidayName: '', 
      date: new Date(), 
      holidayType: 'Public Holiday', 
      isNew: true 
    };

    setRows(prevRows => [
      ...prevRows,
      newHoliday
    ]);

    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'holidayName' },
    }));
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    const img = new Image();
    img.src = logo;
    
    img.onload = () => {
      doc.addImage(img, 'PNG', 13, 10);
      
      const columns = [
        { title: 'Occasion', dataKey: 'holidayName' },
        { title: 'Date', dataKey: 'date' },
        { title: 'Holiday Type', dataKey: 'holidayType' }
      ];

      const data = rows.map(row => ({
        holidayName: row.holidayName,
        date: format(row.date, 'dd-MM-yyyy'),
        holidayType: row.holidayType,
      }));

      doc.autoTable({
        columns,
        body: data,
        margin: { top: 46 }, 
        didDrawPage: (data) => {
          doc.text(`Annual Holidays - ${currentYear}`, data.settings.margin.left, 44);
        },
        headStyles: {
          fillColor: theme.palette.primary.main,
        }
      });      
      
      doc.save(`Phyelements_Holidays_${currentYear}.pdf`);
    };
  }; 

  return (
  <GridToolbarContainer sx={{ height: { xs: '150px', sm: '100px', md: '60px' }, borderBottom: '1px solid lightgray', paddingBottom: '10px' }}>
    <Grid container rowGap={2}>
      <Grid item xs={12} sm={12} md={6.6} sx={{ display: "flex", justifyContent: "flex-start", gap:{xs:'25px', md:'20px'} }}>
        <FormControl sx={{ width: { xs: "48%", sm: '22%', md: '30%' } }} size="small">
          <InputLabel id="month-select-label">Filter by Month</InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth}
            label="Filter by Month"
            onChange={handleMonthChange}
          >
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>January</MenuItem>
            <MenuItem value={2}>February</MenuItem>
            <MenuItem value={3}>March</MenuItem>
            <MenuItem value={4}>April</MenuItem>
            <MenuItem value={5}>May</MenuItem>
            <MenuItem value={6}>June</MenuItem>
            <MenuItem value={7}>July</MenuItem>
            <MenuItem value={8}>August</MenuItem>
            <MenuItem value={9}>September</MenuItem>
            <MenuItem value={10}>October</MenuItem>
            <MenuItem value={11}>November</MenuItem>
            <MenuItem value={12}>December</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: { xs: "45%", sm: '22%', md: '30%' } }} size="small">
          <InputLabel id="year-select-label">Filter by Year</InputLabel>
          <Select
            labelId="year-select-label"
            value={currentYear}
            label="Filter by Year"
            onChange={handleYearChange}
          >
            {nextFiveYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} md={1.8} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start', md: 'flex-end' } }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: { xs: '100%', sm: '90%' }, whiteSpace: 'nowrap'}}
          startIcon={<ImportExportIcon />}
          disabled={!(selectedMonth === 0 || selectedMonth === '')}
          onClick={handleImportClick}
        >
          Import Holiday List
        </Button>
      </Grid>

      <Grid item xs={6} sm={4} md={1.8} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-start', md: 'flex-end' } }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: { xs: '95%', sm: '90%' }, whiteSpace: 'nowrap'}}
          startIcon={<AddIcon />}
          disabled={!(selectedMonth === 0 || selectedMonth === '')}
          onClick={handleClick}
        >
          Add Holiday
        </Button>
      </Grid>

      <Grid item xs={6} sm={4} md={1.8} sx={{ display: 'flex', justifyContent: { xs: 'flex-end', sm: 'flex-start', md: 'flex-end' }}}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: { xs: '90%', sm: '90%' }, whiteSpace: 'nowrap'}}
          startIcon={<GetAppIcon />}
          disabled={filteredHolidays.length === 0 || !(selectedMonth === 0 || selectedMonth === '')}
          onClick={exportToPDF}
        >
          Download
        </Button>
      </Grid>
    </Grid>
  </GridToolbarContainer>
  );
}

const ManageHolidays = () => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedHolidayName, setSelectedHolidayName] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [existingYears, setExistingYears] = useState([]);
  const [targetYear, setTargetYear] = useState(new Date().getFullYear())
  const nextFiveYears = Array.from({ length: 5 }, (_, index) => targetYear + index);

  useEffect(() => {
    fetchHolidays(currentYear);
  }, [currentYear]);

  const fetchHolidays = async (year) => {
    try {
      const holidaysResponse = await fetchHolidaysByYear(year);
      const holidaysWithDates = holidaysResponse.map(holiday => ({
        ...holiday,
        date: parseISO(holiday.date),
      }));
  
      const sortedHolidays = holidaysWithDates.sort((a, b) => a.date - b.date);
      setRows(sortedHolidays);
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = id => () => {
    setRowModesModel(prevModel => ({ ...prevModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = id => () => {
    setRowModesModel(prevModel => ({ ...prevModel, [id]: { mode: GridRowModes.View } }));
  };

  const handleDeleteClick = (id) => () => {
    const holidayToDelete = rows.find(row => row._id === id);
    setSelectedHolidayName(holidayToDelete?.holidayName || '');
    setSelectedId(id);
    setOpenDialog(true);
  };
  
  const handleCancelDialog = () => {
    setOpenDialog(false);
    setSelectedId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteHoliday(selectedId);
      setRows(prevRows => prevRows.filter(row => row._id !== selectedId));
      NotificationManager.success(`Holiday ${selectedHolidayName} deleted successfully`);
    } catch (error) {
      console.error('Error deleting holiday:', error);
      NotificationManager.error('Failed to delete holiday.');
    }
    setOpenDialog(false);
    setSelectedId(null);
  };

  const handleCancelClick = id => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find(row => row._id === id);
    if (editedRow && editedRow.isNew) {
      setRows(prevRows => prevRows.filter(row => row._id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    
    try {
      if (!newRow.holidayName || newRow.holidayName.trim() === '') {
        NotificationManager.error('Occasion cannot be empty');
        return ;
      }
      const localDate = new Date(newRow.date);
      const utcDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
      const updatedRow = {
        ...newRow,
        date: utcDate,
      };
  
      if (newRow.isNew) {
        const { _id, ...newRowData } = updatedRow;
        const newHoliday = await addHoliday(newRowData);
        const finalUpdatedRow = { ...updatedRow, _id: newHoliday._id, isNew: false };
        setRows(prevRows => prevRows.map(row => (row._id === newRow._id ? finalUpdatedRow : row)));
        fetchHolidays(currentYear);
        NotificationManager.success('Holiday added successfully!');
        return finalUpdatedRow;
      } else {
        await updateHoliday(updatedRow._id, updatedRow);
        setRows(prevRows =>
          prevRows.map(row => (row._id === updatedRow._id ? updatedRow : row))
        );
        fetchHolidays(currentYear);
        NotificationManager.success('Holiday updated successfully!');
        return { ...updatedRow, isNew: false };
      }
    } catch (error) {
      console.error('Error updating holiday:', error);
      fetchHolidays(currentYear);
      NotificationManager.error(error.response?.data?.message || 'An error occurred');
      setRows(prevRows => prevRows.filter(row => row._id !== newRow._id));
      return;
    }
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setCurrentYear(event.target.value);
  };

  const fetchExistingYears = async () => {
    try {
        const years = await fetchDistinctYears();
        setExistingYears(years);
        
    } catch (error) {
    }
  };
  
  const handleImportClick = () => {
    fetchExistingYears();
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const filteredHolidays = rows.filter(row => {
    const date = new Date(row.date);
    const monthMatches = !selectedMonth || date.getMonth() === selectedMonth - 1;
    const yearMatches = !currentYear || date.getFullYear() === currentYear;
    const isNewRow = row.isNew;
    
    return (monthMatches && yearMatches) || isNewRow;
  });
  
  const columns = [
    { field: 'holidayName', headerName: <strong>Occasion</strong>, flex: 4, minWidth: 200, editable: true },
    {
      field: 'date', headerName: <strong>Date</strong>, type: 'date', flex: 2.5, minWidth: 200, editable: true,
      renderCell: (params) => format(new Date(params.value), 'dd-MM-yyyy'),
    },
    { field: 'holidayType', headerName: <strong>Holiday Type</strong>, flex: 2.5, minWidth: 200, editable: true, type: 'singleSelect',
      valueOptions: ['Public Holiday', 'Restricted Holiday'] 
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: <strong>Actions</strong>,
      flex: 1, minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        return isInEditMode
          ? [
              <GridActionsCellItem
                key={`save-${id}`}
                icon={<SaveIcon />}
                label="Save"
                color="inherit"
                onClick={handleSaveClick(id)}
                title="Save"
              />,
              <GridActionsCellItem
                key={`cancel-${id}`}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                title="Cancel"
              />,
            ]
          : [
              <GridActionsCellItem
                key={`edit-${id}`}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
                title="Edit"
              />,
              <GridActionsCellItem
                key={`delete-${id}`}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
                title="Delete"
              />,
            ];
      },
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          pl: 9,
          pr: 1,
          pt: 10,
          flex: 1,
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Grid container paddingLeft={1} paddingRight={1} className='grid-container-header' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around' }} marginBottom={0.5}>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontWeight: "bold" }}>Manage Holidays</span>
          </Grid>
        </Grid>
        <Grid container padding={0.5} style={{ backgroundColor: '#fff'}}>
          <Grid item xs={12}>
            <DataGrid
              localeText={{ noRowsLabel: "No Holidays to show." }}
              rows={filteredHolidays}
              columns={columns}
              getRowId={(row) => row._id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows, selectedMonth, handleMonthChange, handleImportClick, currentYear, handleYearChange, nextFiveYears, fetchHolidays, filteredHolidays },
              }}
              autoHeight
              hideFooterPagination
              sx={{
                '& .MuiDataGrid-selectedRowCount': {
                  display: 'none',
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCancelDialog}
      >
        <DialogTitle className="confirmDialogTitle"> Delete Holiday Confirmation <IconButton aria-label="close" onClick={handleCancelDialog}><CancelIcon /></IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity='warning' className='confirmDialogMsg'>
            Are you sure you want to delete the holiday <strong>{selectedHolidayName}</strong>?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCancelDialog} color="primary">
            Cancel
          </Button>
          <Button variant='contained' onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <HolidayPopup open={popupOpen} handleClose={handleClosePopup} refreshHolidayList={() => fetchHolidays(currentYear)} existingYears={existingYears}/>
    </Box>
  );
};

export default ManageHolidays;
