import React, { useState, useEffect } from 'react';
import {
  Grid, Button, Dialog, DialogActions, DialogTitle,
  TextField, Switch, FormControlLabel, Typography, DialogContent, IconButton, useTheme
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationManager } from "react-notifications";
import { createUserRoles, updateUserRole } from '../../services/UserRolesServices';
import '../../CSS/dialogPopup.css';

function UserRolePopup({ open, handleCloseDialog, initialData }) {
  const theme  = useTheme();
  const [userRole, setUserRole] = useState('');
  const [roleNameErrorMessage, setRoleNameErrorMessage] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
  const [activeStatus, setActiveStatus] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (initialData) {
      setUserRole(initialData.userRole || '');
      setDescription(initialData.description || '');
      setActiveStatus(initialData.activeStatus || false);
    } else {
      setUserRole('');
      setDescription('');
      setActiveStatus(true);
    }
    setIsChanged(false);
  }, [initialData]);

  const handleRoleNameChange = (e) => {
    const roleName = e.target.value;
    const regex = /^[A-Za-z\s]*$/;
  
    if (regex.test(roleName)) {
      setUserRole(roleName);
      setRoleNameErrorMessage(roleName ? '' : 'Role name is required');
    } else {
      setRoleNameErrorMessage('Role name can only contain letters');
    }
  
    checkForChanges(roleName, description, activeStatus);
  };

  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setDescription(description);
    setDescriptionErrorMessage(description ? '' : 'Description is required');
    checkForChanges(userRole, description, activeStatus);
  };

  const handleActiveStatusChange = (e) => {
    const newActiveStatus = e.target.checked;
    setActiveStatus(e.target.checked);
    checkForChanges(userRole, description, newActiveStatus);
  };

  const checkForChanges = (newUserRole, newDescription, newActiveStatus) => {
    if (initialData) {
      const isUserRoleChanged = newUserRole !== initialData.userRole;
      const isDescriptionChanged = newDescription !== initialData.description;
      const isActiveStatusChanged = newActiveStatus !== initialData.activeStatus;
      setIsChanged(isUserRoleChanged || isDescriptionChanged || isActiveStatusChanged );
    } else {
      setIsChanged(newUserRole !== '' || newDescription !== '' || newActiveStatus !== true);
    }
  };

  const handleClose = () => {
    setUserRole('');
    setRoleNameErrorMessage('');
    setDescription('');
    setDescriptionErrorMessage('');
    setActiveStatus(true);
    setIsChanged(false);
    handleCloseDialog(false);
  };

  const handleSave = async () => {
    if (!userRole) {
      setRoleNameErrorMessage('Role name is required');
      return;
    }
    if (!description) {
      setDescriptionErrorMessage('Description is required');
      return;
    }

    const userRoleData = { userRole, description, activeStatus };

    try {

      if (initialData && initialData._id) {
        await updateUserRole(initialData._id, userRoleData);
        NotificationManager.success("User Role updated successfully");
      } else {
        await createUserRoles(userRoleData);
        NotificationManager.success("User Role created successfully");
      }
      handleClose();
    } catch (error) {
      NotificationManager.error(error.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '400px' }, className: 'dialog' }}>
      <DialogTitle className= 'dialogTitle'>
        {initialData ? 'Edit User Role' : 'Add User Role'}
        <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              error={!!roleNameErrorMessage}
              helperText={roleNameErrorMessage}
              label="Role Name"
              value={userRole}
              onChange={handleRoleNameChange}
              onBlur={handleRoleNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={3}
              error={!!descriptionErrorMessage}
              helperText={descriptionErrorMessage}
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              onBlur={handleDescriptionChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={activeStatus}
                  onChange={handleActiveStatusChange}
                  color="primary"
                />
              }
              label={<Typography>{activeStatus ? 'Active' : 'Inactive'}</Typography>}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='dialogActions'>
        <Button onClick={handleClose} variant="outlined">Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!userRole || !!roleNameErrorMessage || !isChanged || !description }
        >
          {initialData ? 'Update' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserRolePopup;
