import './App.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoginPage from './components/Login/LoginPage';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import Dashboard from './main';
import Footer from './components/Footer/Footer';
import { getModulePermissionByRoleID, getModulesByIds } from './services/ModuleServices';
import { useEffect, useState } from 'react';
import AllRoutes from './utils/AllRoutes';
import { CircularProgress } from '@mui/material'; 
import { AuthProvider } from './context/AuthContext';
import { useAuth } from './context/AuthContext';
import { getEmployeeById } from './services/employeeService';
import { getRoleIdByName } from './services/UserRolesServices';
import PhyelementsMain from './components/PhyElements/PhyelementsMain';

const AppLayout = () => {
  const location = useLocation();
  const showDashboard = location.pathname !== '/' && location.pathname !== '/login'  ;
  const [userPermissions, setUserPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated} = useAuth();
  const roleId = localStorage.getItem("roleId");
  const employeeId = localStorage.getItem("employeeId");
  const [menu, setMenu] = useState([]);
  
  useEffect(() => {
    const fetchModulesPermission = async () => {
      setLoading(true);
      try {
        const response = await getModulePermissionByRoleID(roleId);
        const moduleIds = response.filter((row) => row.activeStatus)
          .map((row) => row.moduleId);
        const specificModules = await getModulesByIds(moduleIds);
        const associatedMenus = specificModules.map(module => module?.menuItem);
        const emploeeRoleId = await getRoleIdByName(process.env.REACT_APP_EMPLOYEE_ROLE_NAME);
        if(roleId == emploeeRoleId){
          if(employeeId){
            const employeeInfo = await getEmployeeById(employeeId);
            if (
              !employeeInfo || 
              !employeeInfo.aadhaarFile || 
              !employeeInfo.aadhaarNumber ||
              !employeeInfo.address ||
              !employeeInfo.emergencyContactName ||
              !employeeInfo.emergencyContactNumber ||
              !employeeInfo.gender ||
              !employeeInfo.panFile ||
              !employeeInfo.panNumber ||
              !employeeInfo.phone ||
              !employeeInfo.qualification ||
              !employeeInfo.firstName) {
                const filteredMenu = AllRoutes.filter(menuItem => menuItem.title === "My Profile");
                setMenu(filteredMenu);
                setUserPermissions(["myprofile"]);
              }
              else{
                const filteredMenu  =AllRoutes.filter(menuItem => associatedMenus.includes(menuItem.key));
                setMenu(filteredMenu);
                setUserPermissions(associatedMenus);
              }
          }
        }
        else{
          const filteredMenu = AllRoutes.filter(menuItem => associatedMenus.includes(menuItem.key));
          setMenu(filteredMenu);
          setUserPermissions(associatedMenus);
        }
      } catch (error) {
        console.error("Error fetching module permissions: ", error);
      } finally {
        setLoading(false);
      }
    };
    if (roleId) {
      fetchModulesPermission();
    } else {
      setLoading(false);
    }
  }, [roleId]);

  const filteredRoutes = AllRoutes.filter(route => userPermissions.includes(route.key));

  if (loading) {
    return <div className='loading-container'><CircularProgress /></div>; 
  }

  return (
    <>
      {showDashboard && <Dashboard menus={menu} />}
      <Routes>
        <Route path="/" element={<PhyelementsMain  />} />
        <Route path="/login" element={<LoginPage />} />
        { isAuthenticated ? filteredRoutes.map(route => (
          <Route key={route.path} path={route.path}
            element={route.element}
          />
        )) :  <Route path="*" element={<Navigate to="/" replace />} />}
      </Routes>
      <Footer />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <NotificationContainer />
        <AuthProvider>
        <AppLayout />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
