import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { updateLeave, fetchLeavesByEmployeeIdAndDate } from '../../services/leaveServices';
import {
    Grid, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert, IconButton, useTheme
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ApplyLeavePopup from './ApplyLeavePopup';
import { format, parseISO } from 'date-fns';
import { NotificationManager } from 'react-notifications';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ClearIcon from '@mui/icons-material/Clear';
import { Approved, Rejected, Pending, Canceled } from '../../assets/icons/SvgIcons';

export default function ApplyLeave() {
    const theme = useTheme();
    const employeeId = localStorage.getItem("employeeId");
    const [leaves, setLeaves] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalDays, setTotalDays] = useState(0);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    useEffect(() => {
        fetchLeaves();
    }, []);

    const fetchLeaves = async () => {
        try {
            const currentDate = new Date();
            setSelectedEmployeeId(employeeId);
            const fetchedLeaves = await fetchLeavesByEmployeeIdAndDate(employeeId, currentDate);
            const totalDays = fetchedLeaves
                .filter(leave => leave.status === 'Pending' || leave.status === 'Approved')
                .reduce((total, leave) => {
                    const days = parseFloat(leave.numberOfDays) || 0;
                    return total + days;
                }, 0);
            setTotalDays(totalDays);
            setLeaves(fetchedLeaves);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching leaves:', error);
            setLoading(false);
        }
    };

    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';

        const date = parseISO(isoDateString);
        return format(date, 'dd-MM-yyyy');
    };

    const handleCancelClick = (row) => {
        const pendingLopLeave = leaves.find(
            (leave) => leave.leaveType === 'LOP' && leave.status === 'Pending'
        );
        if (row.leaveType !== 'LOP' && row.status === 'Pending' && pendingLopLeave) {
            setConfirmationOpen(true);
        } else {
            cancelLeave(row._id);
        }
    };

    const cancelLeave = async (leaveId) => {
        try {
            await updateLeave(leaveId, 'Cancel'); 
            NotificationManager.success("Leave request canceled successfully");
            fetchLeaves();
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'An error occurred while canceling the leave request');
        }
    };

    const handleCancelConfirmation = () => {
        setConfirmationOpen(false);
    };

    const columns = [
        { field: 'fromDate', headerName: <strong>From Date</strong>, flex: 1, minWidth: 150,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'toDate', headerName: <strong>To Date</strong>, flex: 1, minWidth: 150,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'reason', headerName: <strong>Reason For Leave</strong>, flex: 1.5, minWidth: 200, },
        { field: 'leaveType', headerName: <strong>Leave Type</strong>, flex: 1, minWidth: 150, },
        { 
            field: 'status', 
            headerName: <strong>Status</strong>, 
            flex: 1, minWidth: 150, 
            renderCell: (params) => (
                <span>
                    {params.value === 'Pending' ? (
                        <Pending />
                    ) : params.value === 'Approved' ? (
                        <Approved />
                    ) : params.value === 'Rejected' ? (
                        <Rejected />
                    ) : params.value === 'Canceled' ? (
                        <Canceled />
                    ) : null}
                </span>
            )
        },
        { field: 'comment', headerName: <strong>Comments</strong>, flex: 1, minWidth: 200, },
        {
            field: 'actions',
            headerName: <strong>Actions</strong>,
            flex: 1, minWidth: 150,
            sortable: false,
            renderCell: (params) => (
                <Button variant="contained" style={{maxHeight:'30px',width:'90px'}} disabled={params.row.status === 'Approved' || params.row.status === 'Canceled'} onClick={() => handleCancelClick(params.row)} endIcon={<ClearIcon/>}> Cancel</Button>
            )
        }
    ];

    const handleAddClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
        fetchLeaves();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box sx={{
                pl: 9,
                pr: 1,
                pt: 10,
                flex: 1,
                backgroundColor: theme.palette.secondary.main,
            }}>
                <Grid container paddingLeft={1} paddingRight={1} className='grid-container-header' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around' }} marginBottom={0.5}>
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }} >
                        <span style={{ fontWeight: "bold" }}>My Leaves History</span>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} >
                        <Button variant="contained" onClick={handleAddClick} startIcon={<DateRangeIcon/>}
                        sx={{
                            whiteSpace: 'nowrap',
                            minWidth: { xs: '110px', sm: 'auto' },
                            padding: { xs: '6px 12px', sm: '8px 16px' }
                          }}>
                            Apply Leave
                        </Button>
                    </Grid>
                </Grid>
                <Grid container padding={2} style={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} style={{ height: "375px" }}>
                        <DataGrid
                            localeText={{ noRowsLabel: "No leave requests available. Please add a new request." }}
                            rows={leaves}
                            getRowId={(row) => row._id}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            pagination
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loading}
                            sx={{
                                '& .MuiDataGrid-selectedRowCount': {
                                  display: 'none',
                                },
                              }}
                        />
                    </Grid>
                </Grid>
                <ApplyLeavePopup open={popupOpen} handleCloseDialog={handleClosePopup} employeeId={selectedEmployeeId} totalDays={totalDays} />

                <Dialog
                    open={confirmationOpen}
                    onClose={handleCancelConfirmation}
                    PaperProps={{ sx: { width: '400px' }}}
                >
                    <DialogTitle className="confirmDialogTitle">
                        Cancel Leave Request
                        <IconButton aria-label="close" onClick={handleCancelConfirmation}>
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className='confirmDialogContent'>
                        <Alert severity='warning' className='confirmDialogMsg'>
                            You have a pending LOP leave request. Please cancel the LOP leave first before canceling any other leave request.
                        </Alert>
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={handleCancelConfirmation} variant='contained'>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </Box>
    );
}
