import * as React from 'react';
import {Grid, Box, Button ,InputLabel, MenuItem , FormControl, Select, useTheme } from '@mui/material';
import {fetchUserRoles} from '../../services/UserRolesServices';
import { getModules , getModulePermissionByRoleID} from '../../services/ModuleServices';
import { saveUserRolesPermission } from '../../services/userPermissionService';
import './UserPermissions.css'
import Checkbox from '@mui/material/Checkbox';
import { NotificationManager } from "react-notifications";
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';


export default function UserRolePermissions() {
    const theme = useTheme();
    const [userRole, setUserRole] = React.useState('');
    const [userRoles, setUserRoles] = React.useState([]);
    const [modules, setModules] = React.useState([]);

    const [selectedModules, setSelectedModules] = React.useState([]);
    const [initialSelectedModules, setInitialSelectedModules] = React.useState({});
    const [isChanged, setIsChanged] = React.useState(false);

    const checkIfChanged = (updatedSelectedModules) => {
        const filteredUpdatedModules = Object.fromEntries(
            Object.entries(updatedSelectedModules).filter(([key, value]) => value === true)
        );
        if (Object.keys(initialSelectedModules).length !== Object.keys(filteredUpdatedModules).length) {
            return true
        }
        for (const key of Object.keys(initialSelectedModules)) {
            if (filteredUpdatedModules[key] !== initialSelectedModules[key]) {
                return true
            }
        }
        return false;
      };

    const handleCheckboxChange = (id) => {
        const updatedModules = {
            ...selectedModules,
            [id]: !selectedModules[id], 
        };
        setSelectedModules(updatedModules);
        const changed = checkIfChanged(updatedModules);
        setIsChanged(changed);

    };

    const handleChange = (event) => {
        setUserRole(event.target.value);
        setIsChanged(false);
    };

    const getUserroles = async () =>{
        const data = await fetchUserRoles();
        const sortedData = data.sort((a, b) => a.userRole.localeCompare(b.userRole));
        setUserRole(sortedData[0]._id);
        setUserRoles(sortedData);
    }

    const fetchModules = async () => {

        if (userRole) {
            const data = await getModules();
            setModules(data);
            const modulePermissions = await getModulePermissionByRoleID(userRole);
            const modulePermissionsMap = {};
            modulePermissions.forEach(permission => {
                modulePermissionsMap[permission.moduleId] = permission.activeStatus; 
            });
            setInitialSelectedModules(modulePermissionsMap);
            setSelectedModules(modulePermissionsMap);
        } else {
            setSelectedModules({})
        }
    }

    const handleSaveChanges = async () => {
        const moduleIds = Object.entries(selectedModules)
            .filter(([moduleId, isSelected]) => isSelected) 
            .map(([moduleId]) => moduleId);

        const data = {
            "moduleIds": moduleIds,
            "userRoleId": userRole,
            "activeStatus": true
        }
        const response = await saveUserRolesPermission(data);
        if(response.status == 201){
            NotificationManager.success("Permissions for Modules have been successfully added ");
            setIsChanged(false);
            setInitialSelectedModules(selectedModules);
        }
        else if (response.status == 200) {
            NotificationManager.success("Permissions for Modules have been successfully updated ");
            setIsChanged(false);
            setInitialSelectedModules(selectedModules);
        }

    }
    React.useEffect(() => {
         getUserroles();
    },[])
    React.useEffect(() => {
        fetchModules();
   },[userRole])

    const handleDiscard = () => {
        setSelectedModules(initialSelectedModules);
        setIsChanged(false);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Box sx={{ pl: 9, pr: 1, pt: 10, flex: 1, backgroundColor: theme.palette.secondary.main}}>
                <Grid container rowGap={1}>
                    <Grid item paddingLeft={1} paddingRight={1} xs={12} className="gridItemStyle grid-container-header" >
                        <span style={{ fontWeight: "bold" }}>Permissions </span><span>(Role and Module Mapping)</span>
                    </Grid>
                    <Grid item xs={12} padding={1} className='gridItemStyle'>
                        <FormControl  sx={{width: {xs: '100%', md: '40%'}}}>
                            <InputLabel id="demo-simple-select-label">Select a User Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={userRole}
                                label="Select a User Role"
                                onChange={handleChange}
                            >
                                {userRoles.map((row) => (
                                    <MenuItem value={row._id}>{row.userRole}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} padding={1} className='gridItemStyle' >
                    <span style={{ fontWeight: "bold" }}>App Modules</span><span> (<i>Select the modules accessible to the <strong>{userRoles.filter(x => x._id == userRole).map(x => x.userRole)[0]}</strong></i>)</span><br/><br/>
                        <div style={{display:"flex", flexWrap:"wrap"}}>
                        {modules.map((module)=>(
                            <>
                            <div key={module._id} style={{width:"250px", margin:"10px",backgroundColor: theme.palette.secondary.main, borderRadius:"5px"}}>
                                <Checkbox 
                                color="success" 
                                checked={!!selectedModules[module._id]}
                                // disabled={module.name?.toLowerCase() === myProfileModuleName }
                                onChange={() => handleCheckboxChange(module._id)}
                                />
                                {module.name} 
                            </div> 
                            </>
                        ))}
                        </div>
                        {userRole && 
                        <>
                        <Button variant="contained" startIcon={<RestoreIcon/>} disabled={!isChanged} onClick={handleDiscard}>Discard</Button>
                        <Button variant="contained" style={{textAlign:"right",margin:"10px"}} onClick={handleSaveChanges} startIcon={<UpdateIcon/>} disabled={!isChanged} sx={{ marginBottom: { xs: 3, sm: 2 }}}> Save Changes</Button> 
                        </>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}