import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, CircularProgress, Radio, FormControlLabel, Alert } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDropzone } from 'react-dropzone';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import * as XLSX from 'xlsx';
import { importHolidaysForNewYear, importFromExcel } from '../../services/HolidayServices';
import { useTheme } from '@emotion/react';

const HolidayPopup = ({ open, handleClose, refreshHolidayList, existingYears }) => {
  const theme=useTheme();
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const nextFiveYears = Array.from({ length: 5 }, (_, index) => currentYear + index + 1);

  const [importMethod, setImportMethod] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [toYear, setToYear] = useState(nextYear);
  const [fromYear, setFromYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [confirmReplace, setConfirmReplace] = useState(false);
  const [replace, setReplace] = useState(false);
  const [payload,setPayload] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx, .xls',
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (open) {
      setToYear(nextYear);
      setFromYear('');
      setError('');
      setLoading(false);
      setConfirmReplace(false);
      setReplace(false)
      setSelectedFile(null);
      setImportMethod('')
    }
  }, [open, nextYear]);

  const handleImportFromExcel = async () => {
    if (!selectedFile) {
      setError('Please select a file to import.');
      return;
    }
    setLoading(true);
    setError('');

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        const holidaysToImport = parsedData.map((item) => {
          const parsedDate = excelDateToJSDate(item.date);
          return {
            holidayName: item.holidayName || 'Unknown Holiday',
            date: isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString(),
            holidayType: item.holidayType || 'Public Holiday',
          };
        }).filter(holiday => holiday.date !== null);

        const requestPayload = { holidays: holidaysToImport, toYear, replace };
        setPayload(requestPayload);
        
        try {
          await importFromExcel(requestPayload);
          NotificationManager.success(`Holidays for the year ${requestPayload.toYear} have been replaced successfully.`);
          refreshHolidayList();
          handleClose();
        } catch (error) {
          console.error('Error importing holidays:', error);
          if (error.response && error.response.status === 409) {
            setReplace(true);
          }        
        }
      } catch (error) {
        console.error('Error processing the file:', error);
        NotificationManager.error('An error occurred while processing the file');
      } finally {
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const handleToYearChange = (e) => {
    const selectedYear = e.target.value;
    setToYear(selectedYear);
    
    if (fromYear && fromYear === selectedYear) {
      setError('From Year and To Year cannot be the same.');
    } else if (fromYear && fromYear > selectedYear) {
      setError('From Year should be less than To Year.');
    } else {
      setError('');
    }
  };

  const handleFromYearChange = (e) => {
    const selectedYear = e.target.value;
    setFromYear(selectedYear);
    
    if (toYear && selectedYear === toYear) {
      setError('From Year and To Year cannot be the same.');
    } else if (toYear && selectedYear > toYear) {
      setError('From Year should be less than To Year.');
    } else {
      setError('');
    }
  };

  const handleImport = async () => {
    if (fromYear === toYear) {
      setError('From Year and To Year cannot be the same.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const result = await importHolidaysForNewYear(fromYear, toYear);
      NotificationManager.success(result.message);
      refreshHolidayList();
      handleClose(toYear);
    } catch (error) {
      console.error('Error importing holidays:', error);
      if (error.response && error.response.status === 409) {
        setConfirmReplace(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmReplace = async () => {
    setLoading(true);
    setConfirmReplace(false);
    try {
      await importHolidaysForNewYear(fromYear, toYear, true);
      NotificationManager.success(`Holidays for the year ${toYear} have been replaced successfully.`);
      refreshHolidayList();
      handleClose(toYear);
    } catch (error) {
      console.error('Error replacing holidays:', error);
      NotificationManager.error('Error replacing holidays from previous year.');
    } finally {
      setLoading(false);
    }
  };

  const handleReplace = async () => {
    setLoading(true);
    setReplace(false);
  
    const updatedPayload = { ...payload, replace: true };
  
    try {
      await importFromExcel(updatedPayload);
      NotificationManager.success(`Holidays for the year ${updatedPayload.toYear} have been replaced successfully.`);
      refreshHolidayList();
      handleClose(updatedPayload.toYear);
    } catch (error) {
      console.error('Error replacing holidays:', error);
      NotificationManager.error('Error replacing holidays from excel.');
    } finally {
      setLoading(false);
    }
  };
  
  const excelDateToJSDate = (serial) => {
    const startDate = new Date(Date.UTC(1899, 11, 30));
    const days = Math.floor(serial);
    const milliseconds = (serial - days) * 864e5;
    return new Date(startDate.getTime() + days * 864e5 + milliseconds);
  };
  const handleClosePopup = () => {
    setSelectedFile(null);
    setImportMethod('')
    handleClose(false)
  };

  const handledialogclose=()=>{
    if (confirmReplace) setConfirmReplace(false);
    if (replace) setReplace(false);
  }
  return (
    <>
      <Dialog open={open} PaperProps={{ sx: { width: '450px' }, className: 'dialog' }}>
        <DialogTitle className= 'dialogTitle'>
          Import Holiday List
          <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
        <Box style={{height:'12px', paddingTop:'2px'}}>
        {error && (
            <FormHelperText error style={{ textAlign: 'center', width: '100%' }}>
              {error}
            </FormHelperText>
          )}
        </Box>
          <FormControl fullWidth margin="normal" variant="outlined" style={{ height: '55px' }}>
            <InputLabel id="to-year-label" style={{ fontSize: '12px' }}>Select To Year</InputLabel>
            <Select
              labelId="to-year-label"
              value={toYear}
              onChange={handleToYearChange}
              label="Select to year"
              style={{ fontSize: '12px' }}
            >
              {nextFiveYears.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        <Box borderRadius={1} style={{ padding: '13px', marginTop: '2px', border: `1px solid #cccccc`, display: 'flex', height:'60px' }}>
          <Box display="flex" flexDirection="column" width="35%" justifyContent={'center'}>
            <FormControlLabel
              control={<Radio checked={importMethod === 'newYear'} onChange={() => {
                setImportMethod('newYear');
                setError(null);
                setFromYear(null);
              }}  />}
              label={
                <Typography style={{ fontSize: '12px', color: importMethod === 'newYear' ? 'blue' : 'black' }}>
                  From Year
                </Typography>
              }
            />
            <FormControlLabel
              control={<Radio checked={importMethod === 'excel'} onChange={() => {
                setImportMethod('excel');
                setError(null);
              }} />}
              label={
                <Typography style={{ fontSize: '12px', color: importMethod === 'excel' ? 'blue' : 'black' }}>
                  From Excel
                </Typography>
              }
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '65%',paddingLeft:'5px' }}>
            {importMethod === 'newYear' && (
              <FormControl variant="outlined" style={{ minWidth: '200px'}}>
                <InputLabel id="from-year-label" style={{ fontSize: '12px' }}>From Year</InputLabel>
                <Select
                  labelId="from-year-label"
                  value={fromYear}
                  onChange={handleFromYearChange}
                  label="From Year"
                  style={{ fontSize: '12px' }}
                >
                  {existingYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {importMethod === 'excel' && (
              <div {...getRootProps()} style={{ border: '2px dashed gray', textAlign: 'center', cursor: 'pointer', padding: '8px', width: '180px' }}>
                <input {...getInputProps()} />
                <Typography variant="body1" style={{ fontSize: '12px'}}>
                  {selectedFile ? (
                    <strong>{selectedFile.name}</strong>
                  ) : (
                    'Drag and drop an Excel file here, or click to select one'
                  )}
                </Typography>
              </div>
            )}
          </Box>
        </Box>
        </Box>
        </DialogContent>
        <DialogActions className='dialogActions'>
          <Button onClick={handleClosePopup} variant="outlined">Cancel</Button>
          {importMethod === 'excel' ? (
            <Button onClick={handleImportFromExcel} variant="contained" disabled={!selectedFile}>Import</Button>
          ) : (
            <Button
              onClick={handleImport}
              variant="contained"
              color="primary"
              disabled={!importMethod || error !== ''}
            >
              {loading ? <CircularProgress size={24} /> : 'Import'}
            </Button>
          )}
        </DialogActions>
    
      </Dialog>

      <Dialog open={confirmReplace || replace} onClose={handledialogclose}
        PaperProps={{ sx: { width: '400px' } }}
      >
        <DialogTitle className="confirmDialogTitle" > Replace Holidays Confirmation <IconButton aria-label="close" onClick={handledialogclose}><CancelIcon /></IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity='warning' className='confirmDialogMsg'>
            Holidays list for the year {toYear} already exists. Do you still want to delete the existing ones and create a new list?
          </Alert>
        </DialogContent>
        <DialogActions >
          <Button onClick={handledialogclose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmReplace ? handleConfirmReplace : handleReplace}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HolidayPopup;