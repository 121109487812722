import axios from 'axios';
import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';
const employeeId = localStorage.getItem("employeeId");

export const createEmployee = async (newEmployee) => {
  try {
    const response = await axiosInstance.post(`${baseUrl}/employees/`, newEmployee);
    return response.data;
  } catch (error) {
    console.error('Error creating employee:', error);
    throw error;
  }
};

export const getAllEmployees = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/employees/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export const updateEmployee = async (id, employeeData) => {
  try {
    if (employeeData instanceof FormData) {
      employeeData.append('LastModifiedBy', employeeId);
      employeeData.append('LastModifiedOn', new Date());
    } else{
      employeeData = {
        ...employeeData,
        LastModifiedBy: employeeId,
        LastModifiedOn: new Date(), 
      }
    }
  const response = await axiosInstance.put(`${baseUrl}/employees/${id}`, employeeData);
  return response.data;
  } catch (error) {
      console.error('Error updating  employee:', error);
      throw error;
  }
};

export const updateEmployeeAssingedAssetIds = async (existingEmpId, newEmpId, assignedId) => {
  try {
    const response = await axiosInstance.put(`${baseUrl}/employees/updateAssignedIds`,
      {
        "existingEmpId": existingEmpId, 
        "newEmpId": newEmpId,
        "assignedId": assignedId,
      });
    return response;
  } catch (error) {
    console.error('Error updating  employee:', error);
    throw error;
  }
};

export const removeAsset = async (id, assetdata) => {
  try {
  const response = await axiosInstance.put(`${baseUrl}/employees/${id}/unassign`, assetdata);
  return response.data;
  } catch (error) {
      console.error('Error updating  employee:', error);
      throw error;
  }
};


export const getEmployeeById = async (id) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/employees/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);
    throw error;
  }
}

export const getFileById = async (id) => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/employees/fetchFile/${id}` ,{
      responseType: 'blob',
  });
    // const objectUrl = URL.createObjectURL(response.data);
    // return objectUrl;
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result); // This will be a Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(response.data); // Convert blob to Base64
    });
  } catch (error) {
    console.error('Error fetching File:', error);
    throw error;
  }
}

export const getEmployeeByEmailId = async (token, emailId) => {
  try {
    const response = await axios.get(`${baseUrl}/employees/emailId?emailId=${emailId}`,{
      headers: {
        Authorization: `Bearer ${token}` 
      }
    });
    console.log("response", response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee:', error);
    return [];
  }
}

export const saveLastLoggedIn= async (empId) => {
  try {
      const response = await axiosInstance.put(`${baseUrl}/employees/${empId}`,  { lastLoggedIn :new Date() });
      return response.data;
  } catch (error) {
      console.error('Error updating  loggedInTime:', error);
      throw error;
  }
};

export const getEmployeesByRole = async () => {
  try {
      const response = await axiosInstance.get(`${baseUrl}/employees/ByRole`);
      return response.data;
  } catch (error) {
      console.error('Error fetching employees by role:', error);
      throw error;
  }
};

export const deleteFile = async (employeeId, fileId) => {
  try {
    const response = await axiosInstance.put(`${baseUrl}/employees/${employeeId}/photo`);
    return response.data;
} catch (error) {
    console.error('Error deleting employee photo:', error);
    throw error;
}
};