import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';

export const getAllLeaves = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/leaves`);
      return response.data;
    } catch (error) {
      console.error('Error fetching leaves:', error);
      throw error;
    }
};

export const getLeavesByEmployeeId = async (employeeId) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/leaves/${employeeId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by employee ID:', error);
        throw error;
    }
};

export const getLeavesByApproverId = async (approverId) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/leaves/approver/${approverId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by approver ID:', error);
        throw error;
    }
};

export const createLeave = async (formData) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/leaves`, formData);
        return response.data;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
};

export const updateLeave = async (id, status, comment = '') => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/leaves/${id}`, { status, comment });
        return response.data;
    } catch (error) {
        console.error('Error updating leave:', error);
        throw error;
    }
};

export const deleteLeave = async (leaveId) => {
    try {
        const response = await axiosInstance.delete(`${baseUrl}/leaves/${leaveId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting leave:', error);
        throw error;
    }
};

export const fetchLeavesByEmployeeIdAndDate = async (employeeId, date) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/leaves/${employeeId}/date`, {
            params: { date: date.toISOString() },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching leaves by employeeId and date:', error);
        throw error;
    }
};

export const sendLeaveApprovalEmail = async (emailData) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/sendEmail`, emailData);
        return response.data;
    } catch (error) {
        console.error('Error sending leave approval email :', error);
        throw error;
    }
};
