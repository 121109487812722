import React, { useState, useEffect } from 'react';
import {Grid, Box, Typography, Button, useTheme, TextField, InputAdornment, IconButton, Switch, Checkbox } from '@mui/material';
import AddEmployee from './AddEmployee';
import { getAllEmployees } from '../../services/employeeService';
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { format, parseISO } from 'date-fns';
import {Active, Inactive} from '../../assets/icons/SvgIcons';
import StatusSwitch from '../../assets/icons/StatusSwitch'; 
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import * as XLSX from 'xlsx';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DevicesIcon from '@mui/icons-material/Devices';

function ManageEmployee() {
  const theme = useTheme();
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [pageSize,setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [editEmployee, setEditEmployee] = useState(null);
  const [statusSort, setStatusSort] = useState(true);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchIdCardProvided, setSearcIdCardProvided] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(true);

  const navigate = useNavigate();
  const rowsPerPage = [10, 15, 20, 25]

  function CustomNoRowsOverlay(message) {
    return (
      <div xs={12} style={{ textAlign: "center", paddingTop: "50px" }}>
        <Typography variant="h6" gutterBottom>
          {message}
        </Typography>
      </div>
    );
  }

  useEffect(() => {
    fetchEmployees();
  }, [isAddClicked,editEmployee]);

  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployees();
      setEmployees(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchTerm.length >= 3) {
      handleSearch(searchTerm);
    } else {
      setFilteredEmployees(employees);
    }
  }, [searchTerm, employees]);

  const handleSearch = (term) => {
    const lowercasedTerm = term.toLowerCase();
    const filtered = employees.filter(employee =>
      (employee.firstName && employee.firstName.toLowerCase().includes(lowercasedTerm)) ||
      (employee.emailId && employee.emailId.toLowerCase().includes(lowercasedTerm))
    );
    setFilteredEmployees(filtered);
  };
  
  const formatDate = (isoDateString) => {
    if (!isoDateString) return '';

    const date = parseISO(isoDateString);
    return format(date, 'dd-MM-yyyy');
  };

  const exportToExcel = () => {

    const activeEmployees = employees.filter(employee => employee.activeStatus);
  
    const data = activeEmployees.map((row) => ({
      'Emp Id': row.employeeNumber,
      'First Name': row.firstName,
      'Last Name': row.lastName,
      'Email': row.emailId,
      'Designation': row.designation,
      'Joining Date': formatDate(row.dateOfJoining)
    }));
  
    // Create a new workbook
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
  
    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Active Employees');
  
    // Export to Excel file
    XLSX.writeFile(workbook, 'Employee_List.xlsx');
  };

  const columns = [
  { field: 'employeeNumber', headerName: <strong>Emp Id</strong>, width: 100 },
  { field: 'firstName', headerName: <strong>First Name</strong>, width: 180 },
  { field: 'lastName', headerName: <strong>Last Name</strong>, width: 150 },
  { field: 'emailId', headerName: <strong>Email</strong>, width: 300 },
  { field: 'designation', headerName: <strong>Designation</strong>, width:250},
  { field: 'dateOfJoining', headerName: <strong>Joining Date</strong>, width: 180,
    valueGetter: (params) => formatDate(params),
  },
  { field: 'activeStatus', headerName: <strong>Status</strong>, width: 150,
    renderCell: (params) => (
                <span>{params.value ? <Active/> : <Inactive/>}</span>
    )
  },
  { field: 'actions', headerName: <strong> Actions </strong>, type: 'actions', width:150,
    sortable: false,
    disableColumnMenu: true,
    getActions: (params) => [
      <Tooltip title="Edit">
      <BorderColorIcon 
      onClick={() => { handleEditPopup(params.row); }} 
      style={{ cursor: 'pointer', marginRight: 6 }} 
      />
      </Tooltip>,
      <Tooltip title="Update Employee Profile">
      <PersonIcon 
        onClick={() => { handlePersonAction(params.row); }} 
        style={{ cursor: 'pointer' }} 
      />
      </Tooltip>,
      <Tooltip title="Assets">
      <DevicesIcon 
        onClick={() => { handleAssetsAction(params.row); }} 
        style={{ cursor: 'pointer' }} 
      />
      </Tooltip>
    ],
  },
  ];

  const handleEditPopup = (employee) => {
    setEditEmployee(employee);
    setIsAddClicked(true);
  };

  const handleAddPopup = () => {
    setEditEmployee(null);
    setIsAddClicked(true);
  }

  const handlePersonAction = (employee) => {
    navigate(`/updateprofile/${employee._id}`);
  };

  const handleAssetsAction = (employee) => {
    navigate(`/updateprofile/${employee._id}`, { state: { tabIndex: 2 } });
  };

  const filteredDatas = filteredEmployees.filter((employee) => employee.activeStatus === statusSort);

  const handleManageSearchClick = () => {
    setIsAllChecked(true);
    setShowAdvancedSearch(true);
  };

  const handleCancel = () => {
    setShowAdvancedSearch(false);
    setSearcIdCardProvided(false);
  }

  const handleAdvancedSearch = () => {
    if (isAllChecked) {
      setFilteredEmployees(employees);
    } else {
      const filtered = employees.filter(employee => {
        const nameMatches = searchName
          ? employee.firstName && employee.firstName.toLowerCase().includes(searchName.toLowerCase())
          : true;
  
        const emailMatches = searchEmail
          ? employee.emailId && employee.emailId.toLowerCase().includes(searchEmail.toLowerCase())
          : true;
  
        const idCardProvidedMatches = searchIdCardProvided !== undefined
          ? (employee.idCardProvided === true) === searchIdCardProvided
          : true;
  
        return nameMatches && emailMatches && idCardProvidedMatches;
      });
      setFilteredEmployees(filtered);
    }
    
    setShowAdvancedSearch(false);
    setSearcIdCardProvided(false);
    setSearchName('');
    setSearchEmail('');
  };

  const handleAllChecked = (e) => {
    setIsAllChecked(e.target.checked);
    if (e.target.checked) {
      setSearchName('');
      setSearchEmail('');
      setSearcIdCardProvided(false);
    }
  };
 
  return (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Box
      sx={{
        pl: 9,
        pr: 1,
        pt: 10,
        flex: 1,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Grid container paddingLeft={1} paddingRight={1} rowGap={1} className='grid-container-header' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around' }} marginBottom={0.5}>
        <Grid item xs={12} md={3.8} style={{ display: 'flex', alignItems: 'center' }} >
          <span style={{ fontWeight: "bold" }}>All Employees</span>
        </Grid>
        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent:"center" }}>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', borderRadius:"16px", backgroundColor: theme.palette.secondary.main, width:'100%', padding:'2px' }}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Search Employees"
              value={searchTerm}
              onChange={(e) => {
                const value = e.target.value.trimStart();
                if (value.length <= 50) {
                  setSearchTerm(value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                }
              }}
            />
            <IconButton onClick={handleManageSearchClick}>
              <ManageSearchIcon />
            </IconButton>

            {/* Dropdown for advanced search options */}
            {showAdvancedSearch && (
              <Box
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  marginTop: '5px',
                  padding: '10px',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                  zIndex: 1001,
                  width: '100%',
                  boxSizing: 'border-box'
                }}
              >
                <div style={{display:"flex", gap:"39px"}}>
                  <Typography variant="body1" style={{paddingTop:"6px"}}>
                    All
                  </Typography>
                  <Checkbox
                    checked={isAllChecked}
                    onChange={handleAllChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
                <div style={{display:"flex", gap:"30px"}}>
                  <Typography variant="body1" style={{paddingTop:"6px"}}>
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={searchName}
                    onChange={(e) => {setSearchName(e.target.value)}}
                    disabled={isAllChecked}
                  />
                </div>
                <div style={{display:"flex", gap:"32px"}}>
                  <Typography variant="body1" style={{paddingTop:"6px"}}>
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    style={{ marginBottom: '20px' }}
                    value={searchEmail}
                    onChange={(e) => {setSearchEmail(e.target.value)}}
                    disabled={isAllChecked}
                  />
                </div>
                <div style={{display:"flex", gap:"30px"}}>
                  <Typography variant="body1" style={{paddingTop:"8px"}}>
                    Id Card Issued
                  </Typography>
                  <Switch
                    checked={searchIdCardProvided}
                    onChange={(e) => setSearcIdCardProvided(e.target.checked)}
                    disabled={isAllChecked}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleAdvancedSearch}>
                    Search
                  </Button>
                </div>

              </Box>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5.2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap:'20px' }}>
          <StatusSwitch
            checked={statusSort}
            onChange={(e) => setStatusSort(e.target.checked)}
          />
          <Button variant="contained" disabled={!employees || employees.length === 0} onClick={exportToExcel} startIcon={<ImportExportIcon/>}
            sx={{
              whiteSpace: 'nowrap',
              minWidth: { xs: '143px', sm: 'auto' }
            }}
          >
          Export Employees
          </Button>
          <Button variant="contained" onClick={handleAddPopup} startIcon={<PersonAddIcon/>}
            sx={{
              whiteSpace: 'nowrap',
              minWidth: { xs: '130px', sm: 'auto' }
            }}
          >
          Add Employee
          </Button>
        </Grid>
      </Grid>
       <Grid container padding={2} style={{ backgroundColor: '#fff'}}>
        <Grid item xs={12}  style={{height:"375px"}}>
          <DataGrid
            localeText={{ noRowsLabel: "No Employee available. Please add a new employee." }}
            rows={filteredDatas}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={rowsPerPage}
            getRowId={(row) => row._id}
            components={{
              NoRowsOverlay: () => CustomNoRowsOverlay("No Rows found."),
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
            sx={{
              '& .MuiDataGrid-selectedRowCount': {
                display: 'none',
              },
            }}
          />
        </Grid>
      </Grid>
      <AddEmployee isAddClicked={isAddClicked} handleCloseDialog={() => setIsAddClicked(false)} employeeData={editEmployee} />
    </Box>
  </Box>

  )
}
export default ManageEmployee;