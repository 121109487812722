import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getEmployeeById } from '../../services/employeeService';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MouseIcon from '@mui/icons-material/Mouse';
import AssignedAssetItems from '../Assets/AssignedAssetItems';
import DevicesIcon from '@mui/icons-material/Devices';

const AssignedAssetsList = () => {
  const id = localStorage.getItem('employeeId');
  const [assignedAssets, setAssignedAssets] = useState([]);

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = async () => {
    try {
      const response = await getEmployeeById(id);
      if (response) {
        setAssignedAssets(response.assignedAssetsIds || []);
      } else {
        console.error('No data found in response:', response);
      }
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    }
  };

  const iconMap = {
    Laptop: <LaptopMacIcon fontSize="large" />,
    Monitor: <DesktopWindowsIcon fontSize="large" />,
    Keyboard: <KeyboardIcon fontSize="large" />,
    Mouse: <MouseIcon fontSize="large" />
  };

  const assetTypes = [...new Set(assignedAssets.map(asset => asset.type?.type))];
  
  return (
    <Grid container spacing={1} marginRight={1} marginBottom={6}>
      {assetTypes.map((assetType, index) => {
        const filteredAssets = assignedAssets.filter(asset => asset.type?.type === assetType);
        
        const icon = iconMap[assetType] || <DevicesIcon fontSize="large" />;   

        return filteredAssets.length > 0 && (
          <Grid item xs={12} sm={6} key={index}>
            <AssignedAssetItems 
              assetTypeLabel={assetType} 
              icon={icon} 
              filteredAssets={filteredAssets} 
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AssignedAssetsList;
