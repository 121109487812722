import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchUserRoles } from '../../services/UserRolesServices';
import {
    Grid, Box, Button,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import UserRolePopup from './UseRolePopup';
import {Active, Inactive} from '../../assets/icons/SvgIcons';
import StatusSwitch from '../../assets/icons/StatusSwitch'; 
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTheme } from '@mui/material';

export default function UserRoles() {
    const theme = useTheme();
    const [userRoles, setUserRoles] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statusSort, setStatusSort] = useState(true);
    
    useEffect(() => {
        fetchUserRolesDb();
    }, []);

    const fetchUserRolesDb = async () => {
        try {
          const userRolesResponse = await fetchUserRoles();
          setUserRoles(userRolesResponse);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user roles:', error);
          setLoading(false);
        }
    };

    const filteredDatas = userRoles.filter((role) => role.activeStatus === statusSort);

    const columns = [
        { field: 'userRole', headerName: <strong>Role</strong>, width: 250 },
        { field: 'description', headerName: <strong>Description</strong>, width: 250 },
        { 
            field: 'activeStatus', 
            headerName: <strong>Status</strong>, 
            width: 250, 
            renderCell: (params) => (
                <span>{params.value ? <Active/> : <Inactive/>}</span>
            )
        },
        {
            field: 'actions',
            headerName: <strong>Actions</strong>,
            width: 250,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <BorderColorIcon onClick={() => handleEditClick(params.row)}/>
            )
        }
    ];

    const handleAddClick = () => {
        setSelectedRole(null);
        setPopupOpen(true);
    };
    
    const handleEditClick = (role) => {
        setSelectedRole(role);
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
        fetchUserRolesDb();
    };
    
    return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }}
    >
        <Box  sx={{
            pl: 9,
            pr: 1,
            pt: 10,
            flex:1,
            backgroundColor: theme.palette.secondary.main,
          }}>
            <Grid container paddingLeft={1} paddingRight={1} rowGap={1} className='grid-container-header' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around' }} marginBottom={0.5} >
                <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{fontWeight:"bold"}}>User Roles</span>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap:'20px'}}>
                <StatusSwitch
                    checked={statusSort}
                    onChange={(e) => setStatusSort(e.target.checked)}
                />
                <Button variant="contained" sx={{ whiteSpace: 'nowrap'}} onClick={handleAddClick} startIcon={<ManageAccountsIcon/>}>
                  Add User Role
                </Button>
                </Grid>
            </Grid>
            <Grid container padding={2} style={{ backgroundColor: '#fff'}}>
                <Grid item xs={12}  style={{height:"375px"}}>
                    <DataGrid
                        localeText={{ noRowsLabel: "No user roles available. Please add a new role." }}
                        rows={filteredDatas}
                        getRowId={(row) => row._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        pagination
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                        sx={{
                            '& .MuiDataGrid-selectedRowCount': {
                              display: 'none',
                            },
                          }}   
                    />
                </Grid>
            </Grid>
            <UserRolePopup open={popupOpen} handleCloseDialog={handleClosePopup} initialData={selectedRole} />
        </Box>
    </Box>
    );
}
