import * as React from 'react';
import { Grid, Box, Typography, Button, InputLabel, MenuItem, FormControl, Select, Paper , TextField ,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme , Alert, IconButton } from '@mui/material';
import { getAllEmployees } from '../../services/employeeService';
import { getTimesheetsForEmployeeAndMonth , updatedTimesheet} from '../../services/timesheetService';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NotificationManager } from 'react-notifications';
import { format } from 'date-fns';
import CancelIcon from '@mui/icons-material/Cancel';

const weekDaysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const weekDaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export default function () {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:600px)'); 
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
    const currentMonth = months[new Date().getMonth()];
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 1, currentYear, currentYear + 1];
    const [employeeId, setEmployeeId] = React.useState('');
    const [month, setMonth] = React.useState(currentMonth);
    const [year, setYear] = React.useState(currentYear);
    const [activeEmployees, setActiveEmployees] = React.useState([]);
    const [timesheets, setTimesheets] = React.useState([]);
    const [updateDisabled, setUpdateDisabled] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [modifiedTimesheets, setModifiedTimesheets] = React.useState({});
    
    const employeeHandleChange = async (event) => {
        const selectedEmployeeId = event.target.value;
        setEmployeeId(selectedEmployeeId);

        if (month && year) {
            const data = await getTimesheetsForEmployeeAndMonth(selectedEmployeeId, month, year);
            setTimesheets(data);
        }
    };

    const monthHandleChange = async (event) => {
        const selectedMonth = event.target.value;
        setMonth(selectedMonth);

        if (employeeId && year) {
            const data = await getTimesheetsForEmployeeAndMonth(employeeId, selectedMonth ,year);
            setTimesheets(data);
        }
    };
    const yearHandleChange = async (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);

        if (employeeId && month) {
            const data = await getTimesheetsForEmployeeAndMonth(employeeId, month, selectedYear);
            setTimesheets(data);
        }
    };

    const getActiveEmployess = async () => {
        const data = await getAllEmployees();
        setActiveEmployees(data);
    }

    React.useEffect(() => {
        getActiveEmployess();
    }, [])

    const handleUpdateClick = () => {
        setOpenDialog(true); 
    };

    const handleConfirmUpdate = async () => {
        const updatedTimesheets = timesheets.map(entry => {
            const newHours = modifiedTimesheets[dayjs(entry.date).format('YYYY-MM-DD')];
            if (newHours !== undefined) {
                return { ...entry, hoursLogged: newHours };
            }
            return entry;
        });
        setTimesheets(updatedTimesheets);  
        const daysToUpdate = Object.entries(modifiedTimesheets).map(([dateStr, hoursLogged]) => ({
            date: dayjs(dateStr).format('YYYY-MM-DD'),
            hoursLogged: hoursLogged || 0,
        }));
        const payload = {
            employeeId: employeeId, 
            year: dayjs().year(),
            month: month,
            days: daysToUpdate,
          };
          try {
            const result = await updatedTimesheet(payload);
            NotificationManager.success('TimeSheet Updated Successfully');

            const newTimesheets = await getTimesheetsForEmployeeAndMonth(employeeId, month, year);
            setTimesheets(newTimesheets);

          } catch (error) {
            NotificationManager.error('Something went wrong try again ');
            console.error('Error updating timesheets:', error);
          }
        setModifiedTimesheets({}); 
        setUpdateDisabled(true);  
        setOpenDialog(false); 
    };

    const handleCancelUpdate = () => {
        setOpenDialog(false); 
    };

    const handleHoursChange = (dateStr, newHours) => {
        setModifiedTimesheets(prevState => ({
            ...prevState,
            [dateStr]: newHours
        }));
        setUpdateDisabled(false);
    };

    const renderCalendar = () => {
        if (!timesheets.length) return <Typography>No timesheet data available.</Typography>;

        const monthIndex = months.indexOf(month);
        const startOfMonth = dayjs().month(monthIndex).startOf('month');
        const endOfMonth = dayjs().month(monthIndex).endOf('month');
        const daysInMonth = endOfMonth.date();
        const startDay = startOfMonth.day();
 
        const selectedWeekDays = isMobile ? weekDaysShort : weekDaysFull;
        const calendarDays = [];

        calendarDays.push(
            <Grid container key="header" spacing={0} >
                {selectedWeekDays.map(day => (
                    <Grid item md={1.65} xs={1.7} key={day} 
                        sx={{
                            border: '1px solid #ddd',
                            padding: 1,
                            backgroundColor: '#f5f5f5',
                        }}>
                            <Typography variant="h6" fontWeight="bold"  align="center" color={ theme.palette.primary.main}>{day}</Typography>
                    </Grid>
                ))}
            </Grid>
        );

        for (let i = 0; i < startDay; i++) {
            calendarDays.push(
                <Grid item md={1.65} xs={1.7} key={`empty-${i}`}>
                    <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', border: '1px solid transparent' }}>
                        <Typography variant="body2"></Typography>
                    </Paper>
                </Grid>
            );
        }

        for (let day = 1; day <= daysInMonth; day++) {
            const date = dayjs().month(monthIndex).date(day);
            const dateStr = date.format('YYYY-MM-DD');
            const timesheetEntry = timesheets.find(entry => dayjs(entry.date, 'DD-MMM-YYYY').isSame(dateStr, 'day'));
            const isWeekend = date.day() === 0 || date.day() === 6;
            const isHoliday = timesheetEntry ? timesheetEntry.isHoliday : false;
            const isLeave = timesheetEntry ? timesheetEntry.isLeave : false;

            calendarDays.push(
                <Grid item md={1.65} xs={1.7} key={day}>
                    <Paper elevation={2}
                        sx={{
                            position: 'relative',
                            padding: 1,
                            textAlign: 'center',
                            height: {md:"70px", sm:"50px", xs:"35px"},
                            border: "1px solid #A0A0A0",
                            display: 'flex',
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            backgroundColor: isLeave ? '#e0ffe0' : (isHoliday ? '#ffe0e0' : (isWeekend ? '#f7f7d0' : timesheetEntry? '#d3ffd3' : '#ffffff'))
                        }}
                        onClick={() => {
                            if (!isWeekend) {
                              document.getElementById(`hours-textField-${day}`).focus();
                            }
                          }}
                    >
                        <Typography fontWeight="bold" sx={{
                            color: theme.palette.primary.main,
                            position: 'absolute',
                            bottom: 0,
                            // right: 0,
                            textAlign:'center',
                            margin: 0,
                            padding: {md:'0px', xs:"2px"},
                            // backgroundColor: "#cccccc",
                            // fontSize: { xs: '0.75rem', sm: '1rem' },
                            fontSize: { xs: "9px", sm: "10px", md: "14px" },
                            borderRadius: '50px'
                        }}> {day} </Typography>

                        { !isWeekend 
                        ? (
                        <>
                             <TextField
                                id = {`hours-textField-${day}`}
                                variant="outlined"
                                size="small"
                                value={modifiedTimesheets[dateStr] ?? timesheetEntry?.hoursLogged ?? ''}
                                onChange={(e) => handleHoursChange(dateStr, e.target.value)}
                                sx={{
                                    border:"1px solid #cccccc",
                                    borderRadius: '15px',
                                    width: '100%',
                                    maxWidth: { xs: '40px', sm: '40px' },
                                    // paddingBottom: { xs: '2px', md: '5px'},
                                    marginBottom: '5px',
                                    textAlign: 'center',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',  
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: { xs: '0px', sm: '0px' },
                                        textAlign: 'center',
                                        fontWeight: "bold",
                                        fontSize: { xs: '0.75rem', sm: '1rem' },
                                    }
                                }}
                                onKeyDown={(e) => {
                                    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight'];
                                
                                    if (allowedKeys.includes(e.key)) return;
                                
                                    // Prevent non-numeric keys and prevent entering "." or negative numbers
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                    
                                    // Prevent entering more than 10 hours
                                    const currentValue = e.target.value;
                                    if (parseInt(currentValue + e.key, 10) > 10) {
                                      e.preventDefault();
                                    }
                                    
                                    // Prevent leading zeros if the input is not exactly '0'
                                    if (currentValue === '0' && e.key === '0') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </>
                     ) : (
                        <Typography variant="body2"></Typography> 
                    )} 
                    </Paper>
                </Grid>
            );

            if ((startDay + day) % 7 === 0) {
                calendarDays.push(<Grid item xs={12} key={`break-${day}`} />);
            }
        }

        return (
            <Grid container spacing={0}>
                {calendarDays}
                <Grid item xs={11.9} sm={11.9} md={11.55} marginTop={2} marginBottom={ {xs: 1, sm: 1, md: 0} } textAlign="right">
                <Button variant ="contained" disabled={updateDisabled} onClick={handleUpdateClick}> Update</Button>
                </Grid>
            </Grid>
        );
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ pl: 9, pr: 1, pt: 10, flex: 1, backgroundColor: theme.palette.secondary.main }}>
                <Grid container rowGap={1} marginBottom={4}>
                    <Grid item md={5.5} xs={12} paddingLeft={1} paddingRight={1} className="gridItemStyle grid-container-header" >
                        <span style={{ fontWeight: "bold" }}>Timesheet</span>
                    </Grid>
                    <Grid item md={2} xs={12} padding={1} className='gridItemStyle' >
                        <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                            <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={employeeId}
                                label="Employee"
                                onChange={employeeHandleChange}
                            >
                                {activeEmployees.map((row) => (
                                    <MenuItem value={row._id}>{row.firstName} {row.lastName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.5} xs={6} padding={1} className='gridItemStyle'>
                        <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                            <InputLabel id="month-select-label">Month</InputLabel>
                            <Select
                                labelId="month-select-label"
                                id="month-select"
                                value={month}
                                label="Month"
                                onChange={monthHandleChange}
                            >
                                {months.map((month) => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={1} xs={6} padding={1} className='gridItemStyle'>
                        <FormControl sx={{ width: { xs: '100%', md: '100%' } }}>
                            <InputLabel id="year-select-label">Year</InputLabel>
                            <Select
                                labelId="year-select-label"
                                id="year-select"
                                value={year}
                                label="Year"
                                onChange={yearHandleChange}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={10} xs={12} padding={1}>
                        <Paper elevation={3} sx={{ padding: { md: 3 } }}>
                            {renderCalendar()}
                        </Paper>
                    </Grid>
                    <div style={{display:"flex", flexDirection:"column"}}>
                   
                    </div>
                    <Grid item md={2} xs={12} padding={1}>
                        <Paper elevation={2} sx={{ padding: 2 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>Legend</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box sx={{ width: 16, height: 16, backgroundColor: '#d3ffd3', marginRight: 1 }} />
                                <Typography variant="body2">Filled</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box sx={{ width: 16, height: 16, backgroundColor: '#f7f7d0', marginRight: 1 }} />
                                <Typography variant="body2">Weekend</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box sx={{ width: 16, height: 16, backgroundColor: '#ffffff', marginRight: 1, border: '1px solid #A0A0A0' }} />
                                <Typography variant="body2">Working Day</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog
                    open={openDialog}
                    onClose={handleCancelUpdate}
                >
                    <DialogTitle className="confirmDialogTitle">Update Hours Confirmation <IconButton aria-label="close" onClick={handleCancelUpdate}><CancelIcon /></IconButton></DialogTitle>
                    <DialogContent className='confirmDialogContent'>
                        <Alert severity='warning' className='confirmDialogMsg'>You are about to overwrite the existing hours. Do you want to continue? </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleCancelUpdate} color="primary"> No </Button>
                        <Button variant="contained" onClick={handleConfirmUpdate} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}