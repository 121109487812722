import React, { useState } from 'react';
import {
  Grid, Button, Dialog, DialogActions, DialogTitle,
  TextField, FormControl, InputLabel, Select, MenuItem, 
  IconButton, DialogContent, FormHelperText, DialogContentText, useTheme, Alert
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationManager } from "react-notifications";
import { createLeave ,sendLeaveApprovalEmail} from '../../services/leaveServices';
import { parseISO, eachDayOfInterval, isWeekend } from 'date-fns';

function ApplyLeavePopup({ open, handleCloseDialog, employeeId, totalDays }) {
    const maxLeave = process.env.REACT_APP_EMPLOYEE_MAX_LEAVE_REQUEST
    const theme = useTheme();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [reason, setReason] = useState('');
    const [leaveType, setLeaveType] = useState('');
    const [errors, setErrors] = useState({
        fromDate: '',
        toDate: '',
        reason: '',
        leaveType: ''
    });
    const [showThresholdPopup, setShowThresholdPopup] = useState(false);
    const [thresholdMessage, setThresholdMessage] = useState('');

    const calculateBusinessDays = (startDate, endDate) => {
        const days = eachDayOfInterval({
            start: parseISO(startDate),
            end: parseISO(endDate)
        });

        return days.filter(day => !isWeekend(day)).length;
    };

    const handleFromDateChange = (e) => {
        const newFromDate = e.target.value;
        setFromDate(newFromDate);
        if (toDate && newFromDate > toDate) {
            setErrors(prev => ({
                ...prev,
                fromDate: 'From Date cannot be later than To Date'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                fromDate: ''
            }));
        }
    }
    const handleToDateChange = (e) => {
        const newToDate = e.target.value;
        setToDate(newToDate);
        if (fromDate && newToDate < fromDate) {
            setErrors(prev => ({
                ...prev,
                toDate: 'To Date cannot be earlier than From Date'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                toDate: ''
            }));
        }
    }
    const handleReasonChange = (e) => {
        const reason = e.target.value;
        const regex = /^[A-Za-z0-9\s]*$/;

        if (regex.test(reason)) {
        setReason(reason);
        errors.reason = '';
        } else {
        errors.reason = 'Reason can only contain letters, numbers, and spaces';
        }
    };        
    const handleLeaveTypeChange = (e) => {
        setLeaveType(e.target.value);
        errors.leaveType = '';
    }

    const validate = () => {
        const newErrors = {
            fromDate: fromDate ? '' : 'From Date is required',
            toDate: toDate ? '' : 'To Date is required',
            reason: reason ? '' : 'Reason is required',
            leaveType: leaveType ? '' : 'Leave Type is required',
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleClose = () => {
        setFromDate('');
        setToDate('');
        setReason('');
        setLeaveType('');
        setErrors({
            fromDate: '',
            toDate: '',
            reason: '',
            leaveType: ''
        });
        handleCloseDialog();
    };

    const handleSave = async () => {

        if (!validate()) return;

        const newLeaveDays = calculateBusinessDays(fromDate, toDate);
        const updatedTotalDays = totalDays + newLeaveDays;
        const remainingLeaves = maxLeave - totalDays;
        const lopLeaves = Math.abs(newLeaveDays - remainingLeaves);

        if (updatedTotalDays > maxLeave && remainingLeaves > 0) {
            const message = `You are exceeding the leave threshold. You are eligible to avail ${remainingLeaves} more day(s) of leave without LOP. The remaining ${lopLeaves} day(s) will be treated as Loss of Pay (LOP) if included in this request. Consider splitting your leave request accordingly.`;
            setThresholdMessage(message);
            setShowThresholdPopup(true);
            return;
        }

        const leaveData = { employeeId, fromDate, toDate, reason, leaveType };
        
        try {
            const response = await createLeave(leaveData);
            const emailData = {
                employeeId : employeeId,
                leaveType : leaveType,
                leaveReason : reason,
                fromDate: fromDate,
                toDate: toDate,
                noOfDays: newLeaveDays,
                id : response._id,
            }
            await sendLeaveApprovalEmail(emailData);
            NotificationManager.success("Leave request created successfully");
            handleClose();
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'An error occurred');
        }
    };

    const handleThresholdPopupClose = () => {
        setShowThresholdPopup(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '400px' }, className: 'dialog' }}>
            <DialogTitle className= 'dialogTitle'>
                Add New Leave Request
                <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="From Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={fromDate}
                            onChange={handleFromDateChange}
                            error={!!errors.fromDate}
                            helperText={errors.fromDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="To Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={toDate}
                            onChange={handleToDateChange}
                            error={!!errors.toDate}
                            helperText={errors.toDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={!!errors.leaveType}>
                            <InputLabel>Leave Type</InputLabel>
                            <Select
                                value={leaveType}
                                onChange={handleLeaveTypeChange}
                                label="Leave Type"
                            >
                                <MenuItem value="Sick Leave" disabled={totalDays >= maxLeave}>Sick Leave</MenuItem>
                                <MenuItem value="Casual Leave" disabled={totalDays >= maxLeave}>Casual Leave</MenuItem>
                                <MenuItem value="LOP" disabled={totalDays < maxLeave}>LOP</MenuItem>
                            </Select>
                            {errors.reason && (
                                <FormHelperText>{errors.leaveType}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Reason"
                            multiline
                            rows={3}
                            value={reason}
                            onChange={handleReasonChange}
                            error={!!errors.reason}
                            helperText={errors.reason}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className='dialogActions'>
                <Button variant='outlined' onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSave} disabled={!!Object.values(errors).find(error => error)}>
                    Send
                </Button>
            </DialogActions>

            <Dialog
                open={showThresholdPopup}
                onClose={handleThresholdPopupClose}
                PaperProps={{ sx: { width: '380px' }}}
            >
                <DialogTitle className="confirmDialogTitle">Leave Threshold Exceeded
                    <IconButton aria-label="close" onClick={handleThresholdPopupClose}>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='confirmDialogContent' >
                    <Alert severity='warning' className='confirmDialogMsg' sx={{margin:"15px 0px", textAlign:"justify"}}>
                        {thresholdMessage}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleThresholdPopupClose} variant={'contained'}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </Dialog>
    );
}

export default ApplyLeavePopup;
