import React, { useState } from "react";
import Box from "@mui/material/Box";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack, Backdrop} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import DrawerSidebar from "./components/SideNav/DrawerSideBar";
import Header from "./components/Header/Header";
import {LinearProgress,Typography} from '@mui/material';


const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,

  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: theme.palette.primary.main,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Dashboard = ({menus}) => {
  const [open, setOpen] = useState(false);
  const [backDroploading, setBackDropLoading] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div style={{ position: 'relative' }}>
      {backDroploading && <LinearProgress style={{ zIndex: 1300, position: 'fixed', top: 0, width: '100%' }} />}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDroploading}
      >
        <div>
          <Typography variant="h6" sx={{ marginLeft: 2 }}>
            Logging out, please wait...
          </Typography>
        </div>
      </Backdrop>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          open={open}
          sx={{
            zIndex: 1000,
            backgroundColor: "#fff",
            boxShadow: "none",
            borderBottom: "1px solid #e4e4e4",
          }}
        >
          <Toolbar style={{ padding: 0 }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Header setBackDropLoading={setBackDropLoading} />
            </Stack>
          </Toolbar>
        </AppBar>
        <ClickAwayListener onClickAway={handleDrawerClose}>
          <Drawer
            variant="permanent"
            open={open}
            sx={{ zIndex: 1001}}
          >
            <DrawerHeader
              sx={{
                zIndex: 1001,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                {open ? (
                  <ArrowBackIcon sx={{ color: "#fff", ml: 1 }} />
                ) : (
                  <MenuIcon sx={{ color: "#fff", ml: 1 }} />
                )}
              </IconButton>
            </DrawerHeader>
            <DrawerSidebar menus={menus}
              open={open}
              handleDrawerClose={handleDrawerClose}
            />
          </Drawer>
        </ClickAwayListener>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 8,
            position: "fixed",
            width: "100%",
          }}
        >
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
