import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme
  } from "@mui/material";
  import ActiveListBorder from "../../assets/icons/sidenav_active_list_item.png";
  import InActiveListBorder from "../../assets/icons/sidenav_inactive_list_item.png";
  import { NavLink } from "react-router-dom";
  
  export default function DrawerSidebar({menus, open, handleDrawerClose }) {
    const theme = useTheme();
    const menu = menus;
  
    return (
      <>
        <List sx={{ backgroundColor: theme.palette.primary.main, flexGrow: 1 }}>
          {menu?.map((item, index) => {
            const { icon: ItemIcon } = item;
  
            return (
              <NavLink
                to={item.path}
                key={index}
                style={{ textDecoration: "none" }}
              >
                {({ isActive }) => (
                  <>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={index}
                    >
                      <img
                        src={isActive ? ActiveListBorder : InActiveListBorder}
                        alt="menu icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          objectFit: "contain",
                        }}
                      />
                      <ListItem
                        key={index}
                        disablePadding
                        sx={{
                          display: "block",
                        }}
                      >
                        <ListItemButton
                          selected={isActive}
                          onClick={handleDrawerClose}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            pl: 1.75,
                            pr: 2.5,
                          }}
                        >
                          <ListItemIcon
                            title = {item.title}
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: isActive ? "#ffffff" : "#C9A26B",
                              marginRight:"16px",
                            }}
                          >
                            <ItemIcon                      
                              width={20}
                              height={20}
                              style={{ fill: isActive ? "#ffffff" : "#C9A26B" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              color: isActive ? "#fff" : "#C9A26B",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  </>
                )}
              </NavLink>
            );
          })}
        </List>
        <List sx={{ zIndex: 1001, backgroundColor: theme.palette.primary.main }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  display: open ? "none" : "block",
                  justifyContent: "center",
                }}
              >
              </ListItemIcon>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  display: open ? "block" : "none",
                  padding: "8px",
                  color:"#ffffff"
                }}
              >

              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  }
  