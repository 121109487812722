import React from 'react';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

const AssignedAssetItems = ({ assetTypeLabel, icon, filteredAssets }) => {
  return (
    <Card>
      <Box
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={130}
      >
        <Box display="flex" alignItems="center" width="120px" justifyContent="center">
          <Box ml={2} display="flex" flexDirection="column" alignItems="center">
            {icon}
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}
            >
              {assetTypeLabel}
            </Typography>
          </Box>
        </Box>

        <Box ml={4} flex="1" overflow="hidden">
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: '#C0C0C0' }}>
                <TableCell align="center"><strong>Asset ID</strong></TableCell>
                <TableCell align="center"><strong>Model</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAssets.map((asset, idx) => (
                <TableRow key={idx} style={{backgroundColor: 'transparent'}}>
                  <TableCell align="center" style={{ padding: '4px' }}>{asset.assetId}</TableCell>
                  <TableCell align="center" style={{ padding: '4px' }}>{asset.model?.model}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Card>
  );
};

export default AssignedAssetItems;
