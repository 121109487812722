import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';
export const getTimeSheet = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/timesheettracker/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Timesheet:', error);
    throw error;
  }
};

export const getTimesheetsForEmployeeAndMonth = async (employeeId, month ,year) => {
    const data = await getTimeSheet();
    const employeeData = data.find(emp => emp.employeeId === employeeId);
    if (!employeeData) return [];
    const yearData = employeeData.timesheets.find(ts => ts.year === year);
  if (!yearData) return [];

    const monthData = yearData.months.find(m => m.month === month);
    return monthData ? monthData.days : [];
  };
  
export const updatedTimesheet = async (updatedData) => {
  try {
    const response = await axiosInstance.put(`${baseUrl}/timesheettracker/updateByAdmin`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating  employee:', error);
    throw error;
  }
};