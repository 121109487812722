import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';
export const getTimeTrackerData = async (employeeId, currentYear, currentMonth) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/timesheettracker/${employeeId}`,
        {
            params: {
                year: currentYear,
                month: currentMonth
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching time tracker data:", error);
        throw error;
    }
};

export const postTimeTrackerData = async (employeeId, data) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/timesheettracker/${employeeId}`, data);
        return response.data;
    } catch (error) {
        console.error("Error posting time tracker data:", error);
        throw error;
    }
};

export const fetchHolidaysByYear = async (year) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/holidays/${year}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching holidays for the year ${year}:`, error);
        throw error;
    }
};

export const putTimeTrackerData = async (employeeId, data) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/timesheettracker/${employeeId}`, data);
        return response.data;
    } catch (error) {
        console.error(`Error updating time tracker data:":`, error);
        throw error;
    }
};
