import React, { useState, useEffect } from 'react';
import {
    Grid, Box, FormControl, Select, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent, Alert, DialogActions,
    TextField, Typography, Paper, Button , IconButton
} from '@mui/material';
import { useTheme } from '@mui/material';
import { format, addDays, startOfMonth, endOfMonth, isWeekend, isBefore, isSameDay } from 'date-fns';
import { fetchWeeklyReports, createWeeklyReport } from '../../services/weeklyReportServices';
import { NotificationManager } from 'react-notifications';
import CancelIcon from '@mui/icons-material/Cancel';

export default function MyWeeklyReport() {
    const theme = useTheme();
    const id =  localStorage.getItem("employeeId");

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [reportData, setReportData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const [activeRowIndex, setActiveRowIndex] = useState(null);

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    useEffect(() => {
        const weeksForNewMonth = getWeeksInMonth(selectedYear, selectedMonth);
        const currentWeekIndex = weeksForNewMonth.findIndex(week =>
            isBefore(week.start, new Date()) && isBefore(new Date(), addDays(week.end, 1))
        );
        if (currentWeekIndex >= 0) {
            setSelectedWeek(currentWeekIndex);
            handleWeekChange({ target: { value: currentWeekIndex } });
        }
    }, [selectedMonth, selectedYear]);
    
    useEffect(() => {
        fetchReports();
    }, [selectedWeek, selectedYear, selectedMonth]); 

    const fetchReports = async () => {
        const weekDetails = weeksInMonth[selectedWeek];
        if (!weekDetails) return;
    
        const startDate = format(weekDetails.start, 'yyyy-MM-dd');
        const endDate = format(weekDetails.end, 'yyyy-MM-dd');
    
        try {
            const reports = await fetchWeeklyReports(id, selectedYear, startDate, endDate);
            setOriginalData(reports);
    
                const fetchedReportData = weekDetails.days.map(day => {
                    const report = reports.find(report => format(new Date(report.date), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd'));
                    return {
                        date: day,
                        topic: report ? report.topic : '',
                        description: report ? report.description : '',
                        status: report ? report.status : 'In progress'
                    };
                });
    
                setReportData(fetchedReportData);

        } catch (error) {
            console.error('Error fetching weekly reports:', error);
        }
    };

    const handleYearChange = (event) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
    
        // Set month to January (0)
        const newMonth = 0;
        setSelectedMonth(newMonth);
    
        // Recalculate weeks based on new year and month
        const weeksForNewMonth = getWeeksInMonth(newYear, newMonth);
    
        // Set the first week as the selected week
        if (weeksForNewMonth.length > 0) {
            setSelectedWeek(0);
        }
    };   

    const handleMonthChange = (event) => {
        const newMonth = event.target.value;
        setSelectedMonth(newMonth);
    
        const weeksForNewMonth = getWeeksInMonth(selectedYear, newMonth);
    
        // If there are weeks available, set the first week as the selected week
        if (weeksForNewMonth.length > 0) {
            setSelectedWeek(0);
        }
    };

    const handleWeekChange = (event) => {
        setSelectedWeek(event.target.value);
    };
    
    
    const handleInputChange = (index, field, value) => {
        const updatedData = [...reportData];
        updatedData[index][field] = value;
        setReportData(updatedData);

         // Set the active row index to the current index being edited
        setActiveRowIndex(index);
    };

    const handleSaveButtonState = () => {
        let hasChanges = false;
        let hasAnyData = false;
    
        originalData.forEach((originalRow) => {
        
            // Find the currentRow based on matching date
            const currentRow = reportData.find((row) => {
                const originalDate = new Date(originalRow.date).toDateString();
                const currentDate = new Date(row.date).toDateString();
                return originalDate === currentDate; // Match by date
            });

            if (currentRow && (
                originalRow.topic !== currentRow.topic || 
                originalRow.description !== currentRow.description || 
                originalRow.status !== currentRow.status
            )) {
                hasChanges = true;
                hasAnyData = true;
            }
        });
    
        // 2. Check for new rows (those not present in originalData)
        const allNewRowsFilled = reportData
        .filter(row => !originalData.some(originalRow => {
            return new Date(originalRow.date).toDateString() === new Date(row.date).toDateString();
        }))
        .every(row => {
            // Check if the new row is filled or completely empty
            if (!row.topic && !row.description) {
                return true; // Ignore completely empty new rows
            }
            // If both fields are filled
            if (row.topic && row.description) {
                hasAnyData = true;
                return true;
            }
            // If only one field is filled, the row is incomplete
            return false;
        });
    
        return (hasChanges || allNewRowsFilled) && hasAnyData ;
    };
    
    const isSaveButtonEnabled = handleSaveButtonState();
    
    const isRowModified = activeRowIndex !== null && (reportData[activeRowIndex].topic || reportData[activeRowIndex].description);

    const getWeeksInMonth = (year, month) => {
        const start = startOfMonth(new Date(year, month));
        const end = endOfMonth(new Date(year, month));
        let weeks = [];
        let currentDay = start;
        let weekStart = null;

        while (isBefore(currentDay, end) || isSameDay(currentDay, end)) {
            if (!isWeekend(currentDay)) {
                if (!weekStart) {
                    weekStart = currentDay;
                }

                const isFriday = currentDay.getDay() === 5;
                const isLastDayOfMonth = isSameDay(currentDay, end);

                if (isFriday || isLastDayOfMonth) {
                    const days = [];
                    let tempDay = weekStart;
                    while (isBefore(tempDay, addDays(currentDay, 1))) {
                        if (!isWeekend(tempDay)) days.push(tempDay);
                        tempDay = addDays(tempDay, 1);
                    }
                    weeks.push({
                        start: weekStart,
                        end: currentDay,
                        days,  // Store the weekdays
                    });
                    weekStart = null;
                }
            }
            currentDay = addDays(currentDay, 1);
        }

        return weeks;
    };

    const weeksInMonth = getWeeksInMonth(selectedYear, selectedMonth);

    const handleCancel = () => {
        setOpenDialog(true)
    };

    const handleCancelDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = () => {
        setOpenDialog(false);
        setActiveRowIndex(null);
        fetchReports();
    }; 

    const handleSave = async () => {
        try {
            const formattedReportData = reportData
                .filter(data => data.topic && data.description) // Only include filled rows
                .map(data => ({
                    employeeId: id,
                    date: format(data.date, 'yyyy-MM-dd'),
                    topic: data.topic,
                    description: data.description,
                    status: data.status,
                }));
    
            if (formattedReportData.length > 0) {
                await createWeeklyReport(formattedReportData);
                NotificationManager.success("Weekly report saved successfully");
                setActiveRowIndex(null);
                fetchReports();
            }
    
        } catch (error) {
            console.error('Error saving weekly reports:', error);
            NotificationManager.error("Error saving weekly report");
        }
    };
    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ pl: 9, pr: 1, pt: 10, flex: 1, backgroundColor: theme.palette.secondary.main }}>
                <Grid container paddingLeft={1} paddingRight={1} rowGap={2} style={{ backgroundColor: '#fff', alignContent: 'space-around' }} marginBottom={0.5}>
                    <Grid item xs={12} md={6} className='grid-container-header' style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontWeight: "bold" }}>Weekly Report</span>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth variant="outlined" size="small" sx={{ minWidth: 120, marginRight: 2 }}>
                        <InputLabel>Year</InputLabel>
                            <Select value={selectedYear} label="Year" onChange={handleYearChange} displayEmpty>
                                {[currentYear, currentYear - 1].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth variant="outlined" size="small" sx={{ minWidth: 120, marginRight: { xs: 0, sm: 2, md: 2 } }}>
                        <InputLabel>Month</InputLabel>
                            <Select value={selectedMonth} label="Month" onChange={handleMonthChange} displayEmpty>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <MenuItem key={i} value={i}>
                                        {months[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl fullWidth variant="outlined" size="small" sx={{ minWidth: 120 }}>
                            <InputLabel>Week</InputLabel>
                            <Select
                                value={selectedWeek}
                                onChange={handleWeekChange}
                                label="Week"
                            >
                                {weeksInMonth.map((week, index) => (
                                    <MenuItem key={index} value={index}>
                                        {`Week ${index + 1} (${format(week.start, 'dd/MMM/yyyy')} to ${format(week.end, 'dd/MMM/yyyy')})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Enable Horizontal */}
                <Box sx={{ overflowX: 'auto', maxHeight: '100vh', padding: 1 }}>
                <Paper elevation={1} sx={{ padding: 1, minWidth:{ xs:"967px", sm:"967px", md:"1000px"}}}>
                    <Grid container spacing={2} padding={0} sx={{ minWidth: '1000px' }}>
                        {/* Header Row */}
                        <Grid item xs={1.4}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Date</Typography>
                                </Box>
                        </Grid>
                        <Grid item xs={3.1}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Topic Name</Typography>
                                </Box>
                        </Grid>
                        <Grid item xs={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Description</Typography>
                                </Box>
                        </Grid>
                        <Grid item xs={1.5}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Status</Typography>
                                </Box>
                        </Grid>
                        
                    </Grid>
                </Paper>
                <Grid container spacing={1} padding={0} marginTop={0} marginBottom={2} alignItems={"center"} sx={{ minWidth: '1000px' }}>
                    {/* Data Rows */}
                    {reportData.map((data, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={1.5}>
                                <Paper elevation={1} sx={{ padding: 2.7 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                sx: { border: 'none' },
                                                inputProps: {
                                                    style: { textAlign: 'center' }
                                                }
                                            }}
                                            value={format(data.date, 'dd/MMM/yyyy')}
                                            disabled
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={3}>
                                <Paper elevation={1} sx={{ padding: 0 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2.1}
                                            placeholder="Topic Name"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                sx: { border: 'none' }
                                            }}
                                            value={data.topic}
                                            onChange={(e) => handleInputChange(index, 'topic', e.target.value)}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper elevation={1}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2.1}
                                            placeholder="Description"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                sx: { border: 'none' }
                                            }}
                                            value={data.description}
                                            onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Paper elevation={1} sx={{ padding: 2.6 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl fullWidth variant="standard" >
                                            <Select
                                                value={data.status}
                                                onChange={(e) => handleInputChange(index, 'status', e.target.value)}
                                                disableUnderline
                                                sx={{ border: 'none' }}
                                            >
                                                <MenuItem value="In progress">In progress</MenuItem>
                                                <MenuItem value="Completed">Completed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Paper>
                            </Grid>

                        </React.Fragment>
                    ))}
                </Grid>
            </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
                    <Button variant="contained"
                        sx={{ marginRight: 2 }}
                        onClick={handleCancel}
                        disabled={!isRowModified}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        sx={{ marginRight: 1 }}
                        onClick={handleSave}
                        disabled={!isSaveButtonEnabled}
                    >
                        Save
                    </Button>
                </Box>

                <Dialog open={openDialog} onClose={handleCancelDialog}>
                    <DialogTitle className="confirmDialogTitle">Unsaved Changes Confirmation <IconButton aria-label="close" onClick={handleCancelDialog}><CancelIcon /></IconButton></DialogTitle>
                    <DialogContent className='confirmDialogContent' >
                    <Alert severity='warning' className='confirmDialogMsg'>
                        There are unsaved changes that will be lost. Do you wish to continue?
                    </Alert>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='contained' onClick={handleCancelDialog} color="primary"> No </Button>
                    <Button variant='contained'  onClick={handleConfirmDialog} color="primary"> Continue </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </Box>
    );
}
