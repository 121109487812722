import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 0,
      }}
      component="footer"
    >
      <Container maxWidth="sm" sx={{"height":'30px', marginTop:'8px',}}>
        <Typography variant="body2" color="#C9A26B" align="center" sx={{fontWeight:'bolder'}}>
          {"Copyright © "}
          2024 PHYELEMENTS LLP · All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}
