import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';
export const fetchWeeklyReports = async (employeeId, year, weekStartDate, weekEndDate) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/weeklyReports/byWeek`, {
            params: {
                employeeId,
                year,
                weekStartDate,
                weekEndDate,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching weekly reports:', error);
        throw error;
    }
};

export const createWeeklyReport = async (reportData) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/weeklyReports`, reportData);
        return response.data;
    } catch (error) {
        console.error('Error creating weekly report:', error);
        throw error;
    }
};

export const updateWeeklyReport = async (reportData) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/weeklyReports`, reportData);
        return response.data;
    } catch (error) {
        console.error('Error updating weekly report:', error);
        throw error;
    }
};
