import React from "react";

export const Active = () => {
  return (
    <div style={{ marginTop: '10px' }}>
        <svg width="80" height="32" viewBox="0 0 50 14">
            <rect width="40" height="14" x="0" y="0" rx="7" ry="7" fill="#81BC06" />
            <text x="8" y="10" fill="white" fontSize="8" fontWeight="bold">
                Active
            </text>
        </svg>
    </div>
  );
};


export const Inactive = () => {
    return (
        <div style={{ marginTop: '10px' }}>
            <svg width="80" height="32" viewBox="0 0 50 14">
                <rect width="40" height="14" x="0" y="0" rx="7" ry="7" fill="#B4B4B4" />
                <text x="6" y="10" fill="white" fontSize="8" fontWeight="bold">
                    Inactive
                </text>
            </svg>
        </div>
    );
  };

export const Approved = () => {
    return (
        <div style={{ marginTop: '10px' }}>
            <svg width="80" height="32" viewBox="0 0 50 14">
                <rect width="50" height="15" x="0" y="0" rx="7" ry="7" fill="#81BC06" />
                <text x="7" y="10" fill="white" fontSize="8" fontWeight="bold" >
                    Approved
                </text>
            </svg>
        </div>
    );
};

export const Rejected = () => {
    return (
      <div style={{ marginTop: '10px' }}>
          <svg width="80" height="32" viewBox="0 0 50 14">
              <rect width="50" height="15" x="0" y="0" rx="7" ry="7" fill="#ff3333" />
              <text x="8.5" y="10" fill="white" fontSize="8" fontWeight="bold" >
                  Rejected
              </text>
          </svg>
      </div>
    );
};

export const Pending = () => {
    return (
      <div style={{ marginTop: '10px' }}>
          <svg width="80" height="32" viewBox="0 0 50 14">
              <rect width="50" height="15" x="0" y="0" rx="7" ry="7" fill="#66c2ff" />
              <text x="9" y="10" fill="white" fontSize="8" fontWeight="bold" >
                  Pending
              </text>
          </svg>
      </div>
    );
};

export const Canceled = () => {
    return (
      <div style={{ marginTop: '10px' }}>
          <svg width="80" height="32" viewBox="0 0 50 14">
              <rect width="50" height="15" x="0" y="0" rx="7" ry="7" fill="#FFBA08" />
              <text x="8" y="10" fill="white" fontSize="8" fontWeight="bold" >
                  Canceled
              </text>
          </svg>
      </div>
    );
};

export const Completed = () => {
    return (
        <div style={{ marginTop: '5px' }}>
            <svg width="80" height="32" viewBox="0 0 50 14">
                <rect width="50" height="15" x="0" y="0" rx="7" ry="7" fill="#81BC06" />
                <text x="5" y="10" fill="white" fontSize="8" fontWeight="bold" >
                    Completed
                </text>
            </svg>
        </div>
    );
};

export const InProgress = () => {
    return (
      <div style={{ marginTop: '5px' }}>
          <svg width="81" height="32" viewBox="0 0 53 14">
              <rect width="53" height="15" x="0" y="0" rx="7" ry="7" fill="#66c2ff" />
              <text x="5" y="10" fill="white" fontSize="8" fontWeight="bold" >
                  In Progress
              </text>
          </svg>
      </div>
    );
};