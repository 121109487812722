import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  Link, Grid , Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhyelementsLogo from '../../assets/logos/phyelements_logo.png'
import PersonIcon from '@mui/icons-material/Person';
import { getEmployeeById ,getFileById} from '../../services/employeeService';

const Header = ({ setBackDropLoading }) => {
  const employeeId = localStorage.getItem("employeeId");
  const navigate = useNavigate();

  const [profile, setProfile] = React.useState([]);
  const [profileUrl, setProfileUrl] = React.useState();

  const goLogout  =() => {
    setBackDropLoading(true);
    sessionStorage.clear();
    localStorage.clear();
    var newWindow = window.open('https://accounts.google.com/Logout','Disconnect from Google','menubar=no,status=no,location=no,toolbar=no,scrollbars=no,top=200,left=200');
    setTimeout(function(){
      if (newWindow) newWindow.close();
      navigate('/login')
  },4000);

  }
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const goProfile  =() => {
    navigate('/updateProfile/:id');
  }

  useEffect(()=>{
    getUserProfileDetail();   
  },[])
  
  const getUserProfileDetail = async () =>{
   const response = await getEmployeeById(employeeId);
   if (response.photo){
    const url = await getFileById(response.photo.fileId);
    setProfileUrl(url);
   }
   setProfile(response);
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid container >
        <Grid item xs={2} md={2} style={{ }}>        
          <img alt="phyelements"  src={PhyelementsLogo} style={{width:"220px",height:"auto", paddingTop:"10px"}}/>       
        </Grid>
        <Grid item xs={8} md={8} className='header-center'>
            phye HRMS Portal
        </Grid>
        
        <Grid item xs={2} md={1} style={{margin:"auto", textAlign:"right"}}>
          <Tooltip title="Profile">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src={profileUrl}></Avatar>  
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              style: {
                width: '250px',
              },
            }}
          >
            <Typography sx={{ display: 'block', textAlign: 'center', color: "#11111" , fontSize:".9rem", fontWeight:'200'}}>
            Hello <strong>{ profile.firstName } { profile.lastName }</strong>
          </Typography>
             <Typography sx={{ display: 'block', textAlign: 'center', color: "#B8B8B8" , marginBottom:"10px" }}>
            {profile.emailId}
          </Typography>
          <Divider />
            <Link style={{textDecoration:'none'}} href='#' title='Profile' onClick={() => { handleCloseUserMenu(); goProfile() }}>
              <MenuItem >
                <PersonIcon/>&nbsp;&nbsp;&nbsp;My Profile
              </MenuItem>
            </Link>  
            <Link style={{textDecoration:'none'}} href='#' title='Logout' onClick={goLogout}>
              <MenuItem onClick={handleCloseUserMenu}> <ExitToAppIcon></ExitToAppIcon>&nbsp;&nbsp;&nbsp;Logout</MenuItem>
            </Link>      
          </Menu>
        </Grid>
      </Grid>      
    </div>
  );

}


export default Header;
