import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

const StatusSwitch = ({ checked, onChange }) => {
    
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} color="primary" />}
      label={checked ? 'Active' : 'Inactive'}
      sx={{ m: 0 }}
    />
  );
};

export default StatusSwitch;
