import axiosInstance from './axiosInstance';
import { baseUrl } from '../Constants';

export const getModules = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/modules`);
    // console.log('response.data', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching modules:', error);
    throw error;
  }
};

export const updateModule = async (moduleId, updatedModuleData) => {
  try {
    await axiosInstance.put(`${baseUrl}/modules/${moduleId}`, updatedModuleData);
  } catch (error) {
    console.error('Error updating module:', error);
    throw error;
  }
};

export const insertModule = async (newModuleData) => {
  try {
    await axiosInstance.post(`${baseUrl}/modules`, newModuleData);
  } catch (error) {
    console.error('Error inserting module:', error);
    throw error;
  }
};

export const getModulePermissionByRoleID = async (userRoleId) => {
  try {
      // console.log('userRoleId',userRoleId);
      const checkResponse = await axiosInstance.get(`${baseUrl}/moduleUserRole/${userRoleId}`);
      if (checkResponse.status === 200) {
      }
      return checkResponse.data.data;
  } catch (error) {
      if (error.response && error.response.status === 404) {
          return [];
      } else {
          console.error('Error creating or updating user roles:', error);
          throw error;
      }
  }
};

export const getModulesItems = async () => {
  try {
    const response = await axiosInstance.get(`${baseUrl}/modules/menu`);
    // console.log('Modules list data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items:', error);
    throw error;
  }
};

export const getModulesByIds = async (moduleIds) => {
  try {
    // console.log('moduleIds',moduleIds);
    const response = await axiosInstance.post(`${baseUrl}/modules/modulesByIds`, {moduleIds});
    // console.log('modules data', response.data);
    return response.data;
  } catch (error) {
    console.error("Error in fetching the data", error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error;
  }
};