import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Tooltip, IconButton, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ModulePopup from './ModulePopup';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { getModules } from '../../services/ModuleServices';
import {Active, Inactive} from '../../assets/icons/SvgIcons';
import StatusSwitch from '../../assets/icons/StatusSwitch';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'; 

function Modules() {
  const theme  = useTheme();
  const [datas, setDatas] = useState([]);
  const [openDataHandle, setOpenDataHandle] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusSort, setStatusSort] = useState(true);

  useEffect(() => {
    fetchModuleList();
  }, []);

  const fetchModuleList = async () => {
    try {
      const data = await getModules();
      setDatas(data);
    } catch (error) {
      console.error("Error in fetching the data", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: (
        <Tooltip title="Name">
          <strong>Name</strong>
        </Tooltip>
      ),
      width: 200
    },
    {
      field: 'description',
      headerName: (
        <Tooltip title="Description">
          <strong>Description</strong>
        </Tooltip>
      ),
      width: 650
    },
    {
      field: 'activeStatus',
      headerName: (
        <Tooltip title="Status">
          <strong>Status</strong>
        </Tooltip>
      ),
      width: 150,
      renderCell: (params) => (
        <span>{params.value ? <Active/> : <Inactive/>}</span>
      )
    },
    {
      field: 'edit',
      headerName: (
        <Tooltip title="Action">
          <strong>Action</strong>
        </Tooltip>
      ),
      width: 100,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditModule(params.row)}>
              <BorderColorSharpIcon />
            </IconButton>
          </Tooltip>
        );
      }
    }
  ];

  const handleAddModule = () => {
    setIsUpdate(false);
    setOpenDataHandle(true);
    setSelectedModule(null);
  };

  const handleEditModule = (module) => {
    setSelectedModule(module);
    setIsUpdate(true);
    setOpenDataHandle(true);
  };

  const handleCloseDataHandle = () => {
    setOpenDataHandle(false);
    setSelectedModule(null);
    fetchModuleList();
  };

  const filteredDatas = datas.filter((module) => module.activeStatus === statusSort);
  
  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Box sx={{ pl: 9, pr: 1, pt: 10, backgroundColor: theme.palette.secondary.main, flex: 1 }}>
          <Grid container paddingLeft={1} paddingRight={1} rowGap={1} className='grid-container-header' marginBottom={0.5}
            sx={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around' }}
          >
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center'}} >
              <Typography variant="h6" fontWeight="bold">App Modules</Typography>
            </Grid> 
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap:'20px'}}>
              <StatusSwitch
                checked={statusSort}
                onChange={(e) => setStatusSort(e.target.checked)}
              />
              <Button variant="contained" onClick={handleAddModule} startIcon={<DashboardCustomizeIcon/>} 
              sx={{
                whiteSpace: 'nowrap'
              }}>
                Add Module
              </Button>
            </Grid>
          </Grid>
          <Grid container padding={2} sx={{ backgroundColor: '#fff' }}>
            <Grid item xs={12} sx={{ height: "375px" }}>
              <DataGrid
                localeText={{ noRowsLabel: "No user modules available. Please add a new user module." }}
                rows={filteredDatas}
                columns={columns}
                initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
                pageSizeOptions={[5, 10, 25]}
                pagination
                loading={loading}
                components={{
                  LoadingOverlay: () => <div>Loading...</div>
                }}
                getRowId={(row) => row._id}
                sx={{
                  '& .MuiDataGrid-selectedRowCount': {
                    display: 'none',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ModulePopup 
        open={openDataHandle} 
        handleClose={handleCloseDataHandle} 
        module={selectedModule} 
        refreshData={fetchModuleList} 
        isUpdate={isUpdate} 
      />
    </div>
  );
}

export default Modules;
